<template>
    <div id="wrapper" class="container-fluid">
        <div class="row subhdr">
            <div class="col-sm-11 offset-sm-1">
                <h1 class="ctr">Status Report for {{ $store.state.purchaserFullName }}</h1>
                <br />
                <div class="spacer">
                    <h4><strong>Introduction to Your Status Report</strong></h4>
                    <span>The status report lists the leaders for each school and displays the progress of each of their participants.</span>
                </div>
            </div>
        </div>
        <br />

        <div class="row" v-if="notStarted">
            <div class="col-sm-11 offset-sm-1">
                <h3 class="ctr">There is not yet any data to display.</h3>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-10 offset-sm-1 userlist" v-for="school in schools" :key="school">
                <h3 class="schoolhdr">{{ school }}</h3>
                <div v-for="leader in leaders" :key="leader.username" v-show="leader.school==school">
                    <div class="col-sm-12 ldrhdr">
                        Leader: {{ leader.fullName }} &nbsp;&nbsp;&nbsp;<em>{{ leader.email }}</em>
                        <span class="hdrdata">
                            <span v-if="leader.ldrSurveyComplete">The Leader has completed the survey.</span>
                            <span v-else>The Leader has not completed the survey.</span>
                            <span v-if="leader.completedSurveys + leader.incompleteSurveys > 0">
                                Out of {{ leader.completedSurveys + leader.incompleteSurveys }} participants, {{ leader.incompleteSurveys }}
                                {{ leader.incompleteSurveys==1 ? 'has' : 'have' }} not completed the survey.&nbsp;
                                {{ (leader.completedSurveys + leader.incompleteSurveys)==0 ? 0 : Math.round((leader.completedSurveys / (leader.completedSurveys + leader.incompleteSurveys)) * 100) }}% response rate from participants.
                            </span>
                            <span v-else>
                                There are currently no participant responses.
                            </span>
                        </span>
                    </div>

                    <div class="col-sm-12 table-responsive">
                        <table class="table">
                            <colgroup>
                                <col style="width:20%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:7%">
                                <col style="width:11%">
                                <col style="width:11%">
                                <col style="width:11%">
                                <col style="width:15%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Leader</th>
                                    <th scope="col"></th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Progress</th>
                                    <th scope="col">Initial Date</th>
                                    <th scope="col">Deadline Date</th>
                                    <th scope="col">Extension Date</th>
                                    <th scope="col">Report Forced Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="participant in participants" :key="participant.username" v-show="participant.username==leader.username">
                                    <td>{{ participant.fullName }}</td>
                                    <td>{{ participant.email }}</td>
                                    <td>{{ participant.role }}</td>
                                    <td>{{ participant.progressPercent }}%</td>
                                    <td>{{ participant.initialDate }}</td>
                                    <td>{{ participant.deadlineDate }}</td>
                                    <td>{{ participant.extensionDate }}</td>
                                    <td>{{ participant.forcedDate }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table">
                            <colgroup>
                                <col style="width:20%">
                                <col style="width:15%">
                                <col style="width:10%">
                                <col style="width:7%">
                                <col style="width:11%">
                                <col style="width:11%">
                                <col style="width:11%">
                                <col style="width:15%">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Participants</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="participant in participants" :key="participant.username"
                                    v-show="participant.leaderUsername==leader.username && participant.role=='Supervisor'">
                                    <td>{{ participant.fullName }}</td>
                                    <td>{{ participant.email }}</td>
                                    <td>{{ participant.role }}</td>
                                    <td>{{ participant.progressPercent }}%</td>
                                    <td>{{ participant.initialDate }}</td>
                                    <td>{{ participant.deadlineDate }}</td>
                                    <td>{{ participant.extensionDate }}</td>
                                    <td>{{ participant.forcedDate }}</td>
                                </tr>
                                <tr v-for="participant in participants" :key="participant.username"
                                    v-show="participant.leaderUsername==leader.username && participant.username!=leader.username && participant.role!='Supervisor'">
                                    <td>{{ participant.fullName }}</td>
                                    <td>{{ participant.email }}</td>
                                    <td>{{ participant.role }}</td>
                                    <td>{{ participant.progressPercent }}%</td>
                                    <td>{{ participant.initialDate }}</td>
                                    <td>{{ participant.deadlineDate }}</td>
                                    <td>{{ participant.extensionDate }}</td>
                                    <td>{{ participant.forcedDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "StatusReport",
        data() {
            return {
                leaders: [],
                participants: [],
                schools: [],
                notStarted: false
            }
        },

        methods: {
            GetPurchaserInfo() {
                axios.get('purchaser/purchaserinfo')
                    .then((res) => {
                        this.$store.state.purchaserFullName = res.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            GetStatusReport() {
                axios.get('purchaser/statusreport', { params: { viewSession: this.$store.state.viewSession, purchaserName: this.$store.state.purchaserAlias }})
                    .then((res) => {
                        if (res.data[0].length == 0 && res.data[1].length == 0 && res.data[2].length == 0) {
                            this.notStarted = true;
                        } else {
                            this.participants = res.data[0];
                            this.schools = res.data[1];
                            this.leaders = res.data[2];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            }
        },

        mounted() {
            document.title = "Turnaround 360";

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                if (this.$store.state.purchaserFullName == "") {
                    this.GetPurchaserInfo();
                }
                this.GetStatusReport();
            }
        }
    }
</script>

<style scoped>
    .actionbtn {
        margin-right: 16px;
    }

    .hdrdata {
        float:right;
        margin-right: 15px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px 0;
    }

    .ldrhdr {
        background-color: #003399;
        color: #ffffff;
        padding: 5px;
        margin-bottom: 16px;
        display: inline-block;
    }

    .schoolhdr {
        padding: 7px;
        background-color: #cce0ea;
    }

    .userlist {
        background-color: #e1e9ed;
    }

    .spacer {
        margin-left: 6px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>