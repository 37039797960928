<template>
    <div id="wrapper">
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Reset",

        methods: {
            Reset() {   // Browser refresh wipes global variables so application comes here if user refreshes page where login info is required
                axios.get('account/getloggedinfo')
                    .then((res) => {
                        this.$store.state.loggedName = res.data[0];
                        this.$store.state.userRole = res.data[1];
                        this.$store.state.surveyActive = res.data[2];
                        this.$store.state.leaderName = res.data[3];
                    }).then(() => {
                        switch (this.$store.state.userRole) {
                            case "Administrator":
                                this.$router.push('/admin');
                                break;
                            case "Purchaser":
                                this.$router.push('/purchaser');
                                break;
                            case "Leader":
                                this.$router.push('/leader');
                                break;
                            default:
                                this.$router.push('/SurveyInstructions');
                        }
                    });
            }
        },

        mounted() {
            this.Reset();
        }
    }

</script>

<style scoped>
    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>