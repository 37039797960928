<template>
    <div id="wrapper">
        <div class="subhdr">
            <br />
            <h1 class="ctr">Login</h1>
            <br />
            <div class="row">
                <div class="col-8 col-sm-6 col-md-6 col-lg-4 offset-2 offset-sm-3 offset-md-3 offset-lg-4">
                    <form method="post" class="frmstyle">
                        <div class="form-group">
                            <input name="Username" type="text" v-model="username" class="form-control" placeholder="Username" aria-label="Username"
                                   v-bind:class="[(this.usernameFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <div class="form-group">
                            <input name="Password" type="password" v-model="password" class="form-control" placeholder="Password" aria-label="Password"
                                   v-bind:class="[(this.pwdFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <button type="submit" class="btn btn-large actionbtn ctr" @click.prevent="Submitted">Login</button>
                    </form>
                    <br />
                    <p class="warning ctr" v-if="!success">{{ warning }}</p>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-12 ctr signup">
                If you have been invited to be a participant but don't yet have your login credentials, click here:
            </div>
            <form method="post" class="frmstyle">
                <br />
                <button type="submit" class="btn btn-large actionbtn ctr" @click.prevent="CreateAccount">Set Up Account</button>
            </form>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Login",

        data() {
            return {
                title: "Login",
                username: "",
                password: "",
                success: true,
                warning: "Login failed. Please try again.",
                usernameFail: false,
                pwdFail: false,
                userRole: ""
            }
        },

        methods: {
            Submitted() {
                if (this.username != "") {
                    this.usernameFail = false;
                    if (this.password != "") {
                        this.pwdFail = false;
                        var postData = { "Username": this.username, "Password": this.password };
                        axios.post('account/login', postData)
                            .then((res) => {
                                this.$store.state.loggedName = this.username;
                                this.$store.state.userRole = res.data[0];
                                this.$store.state.surveyActive = res.data[1];
                                this.$store.state.leaderName = "";
                                this.$store.state.purchaserFullName = "";
                                this.$store.state.leaderFullName = "";
                                this.$store.state.leaderAlias = "";
                                this.$store.state.purchaserAlias = "";
                                this.$store.state.viewSession = "";
                                this.$store.state.searchUser = "";
                                this.$store.state.surveys = [];
                                this.NextStep(this.$store.state.userRole);
                            })
                            .catch((error) => {
                                this.success = false;
                                this.warning = error.response.data;
                                if (this.warning === 'User does not exist') {
                                    this.usernameFail = true;
                                } else {
                                    if (this.warning === 'Invalid username or password.') {
                                        this.pwdFail = true;
                                    }
                                }
                            });
                    } else {
                        this.warning = "You must enter a password.";
                        this.pwdFail = true;
                        this.success = false;
                    }
                } else {
                    this.warning = "You must enter a valid username.";
                    this.usernameFail = true;
                    this.success = false;
                }
            },

            NextStep(userRole) {
                switch (userRole) {
                    case "Administrator":
                        this.$router.push('/admin');
                        break;
                    case "Purchaser":
                        this.$router.push('/purchaser');
                        break;
                    case "Leader":
                        this.$store.state.leaderName = this.$store.state.loggedName;
                        this.$router.push('/leader');
                        break;
                    case "Supervisor":
                    case "Teacher":
                    case "Leadership Team":
                        this.$router.push('/surveyinstructions');
                        break;
                }
            },

            CreateAccount() {
                this.$router.push('/signup');
            }
        },

        mounted() {
            document.title = "Turnaround 360";
        },
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .signup {
        color: #c72d0d;
    }

    .frmstyle {
        text-align: center;
    }

    button {
        background-color: #0d6efd;
        border-color: transparent;
        color: #ffffff;
    }

    button:hover {
        background-color: #68a4fd;
        border-color: #ab935c;
        border-width: 1px;
    }

    .warning {
        color: #e60000;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>