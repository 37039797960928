<template>
    <!-- This page used solely for initial setup (populating roles and admins in database) -->
    <h1>Register</h1>

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <form method="post">
                <div class="form-group">
                    <input name="Email" type="text" v-model="email" class="form-control" placeholder="Email" aria-label="Email"/>
                </div>
                <br />
                <div class="form-group">
                    <input name="FirstName" type="text" v-model="firstName" class="form-control" placeholder="First Name" aria-label="First Name"/>
                </div>
                <br />
                <div class="form-group">
                    <input name="LastName" type="text" v-model="lastName" class="form-control" placeholder="Last Name" aria-label="Last Name"/>
                </div>
                <br />
                <div class="form-group">
                    <input name="Password" type="text" v-model="password" class="form-control" placeholder="Password" aria-label="Password"/>
                </div>
                <br />
                <div class="form-group">
                    <input name="ConfirmPassword" type="text" v-model="confirmPassword" class="form-control" placeholder="Confirm Password" aria-label="Confirm Password"/>
                </div>
                <br />
                <button type="submit" class="btn-primary" @click.prevent="Submitted" disabled="disabled">Register</button>
                <br />
                <br />
                <br />
                <span>Only do this once!</span>
                <br />
                <button type="submit" class="btn-primary" @click.prevent="CreateRoles" disabled="disabled">Create Roles</button>
            </form>
        </div>
    </div>
</template>


<script>
    import axios from 'axios';

    export default {
        name: "Register",
        data() {
            return {
                title: "Register",
                email: "",
                firstName: "",
                lastName: "",
                userRole: "",
                password: "",
                confirmPassword: ""
            }
        },

        methods: {
            Submitted() {
                if (this.email != "" && this.password != "" && this.firstName != "" && this.lastName != "") {
                    if (this.password === this.confirmPassword) {    
                        var postData = { "Email": this.email, "Password": this.password, "FirstName": this.firstName, "LastName": this.lastName };
                        axios.post('account/register', postData)
                            .then(() => {
                                console.log("Submission successful.");
                            })
                            .catch((error) => {
                                console.log("Error during submission.");
                                alert(error);
                        });
                    }
                }
            },

            CreateRoles() {
                axios.post('setup/roles')
                    .then(() => {
                        console.log("Roles created");
                    })
                    .catch((error) => {
                        console.log("Error creating roles");
                        alert(error);
                });
            }
        }
    }
</script>
