<template>
    <div id="wrapper">
        <div class="subhdr">
            <div class="col-6 offset-3 col-sm-9">
                <div class="row">
                    <div class="col-sm-8 col-md-6 offset-1">
                        <h1>Invite Responders</h1>
                        <br />
                        <form method="post">
                            <div class="form-group">
                                <input name="AddEmail" type="text" v-model="inviteEmail" class="form-control" placeholder="Email" aria-label="Email Address"
                                       v-bind:class="[(this.inviteFail && this.inviteEmail=='') ? 'warnstyle' : 'normalstyle']" />
                            </div>
                            <div class="form-group">
                                <input name="FirstName" type="text" v-model="firstName" class="form-control" placeholder="First Name" aria-label="First Name"
                                       v-bind:class="[(this.inviteFail && this.firstName=='') ? 'warnstyle' : 'normalstyle']" />
                            </div>
                            <div class="form-group">
                                <input name="LastName" type="text" v-model="lastName" class="form-control" placeholder="Last Name" aria-label="Last Name"
                                       v-bind:class="[(this.inviteFail && this.lastName=='') ? 'warnstyle' : 'normalstyle']" />
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6 offset-1">
                        <p class="invbtn"><button type="submit" class="btn btn-large invitebtn" @click.prevent="InviteUser">Send Invitation</button></p>
                    </div>
                    <div class="col-lg-3 col-md-6 offset-1 pt-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" @change="SelectSupervisor">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Supervisor
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked @change="SelectTeacher">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Teacher
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" @change="SelectLeadership">
                            <label class="form-check-label" for="flexRadioDefault3">
                                Leadership Team
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <p>Your leader token is: <strong>{{ leaderToken }}</strong></p>
                    <em>You can give this token to participants after you add them here.</em>
                    <br />
                    <em>Emails are automatically sent to participants as soon as you add them here. However, in some cases users do not receive their invite email.</em>
                    <em>In that case, you can give them this token and they can use it to get their credentials on our login page.</em>
                </div>
                <br />

                <div class="row">
                    <div class="col-sm-3 offset-sm-3">
                        <p class="ctr" v-bind:class="[(this.inviteFail) ? 'warning' : 'notice']">{{ inviteMessage }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-8 offset-sm-2 table-responsive">
                <h3 class="invitehdr">Invited</h3>
                <table class="table">
                    <colgroup>
                        <col style="width:20%">
                        <col style="width:20%">
                        <col style="width:30%">
                        <col style="width:30%">
                    </colgroup>
                    <thead>
                        <tr class="tblhdr">
                            <th scope="col">&nbsp; Role</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="hdrrow">
                            <td>Supervisor</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{{ supervisor.fullName }}</td>
                            <td>{{ supervisor.email }}</td>
                            <td>
                                <button class="btn actionbtn" v-scroll-to="{el: '#changeRoleBox', duration: 100}"
                                        @click="VerifyRoleChange(supervisor.username, supervisor.fullName, 'Supervisor')" v-if="supervisor.fullName!='Not Yet Invited'">
                                    Change Role
                                </button>
                                <button class="btn actionbtn" v-scroll-to="{el: '#deleteBox', duration: 100}"
                                        @click="RemoveUser(supervisor.username, supervisor.fullName)" v-if="supervisor.fullName!='Not Yet Invited'">
                                    Remove
                                </button>
                            </td>
                        </tr>
                        <tr class="hdrrow">
                            <td>Teachers</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-for="t in teachers" :key="t.username">
                            <td></td>
                            <td>{{ t.fullName }}</td>
                            <td>{{ t.email }}</td>
                            <td>
                                <button class="btn actionbtn" v-scroll-to="{el: '#changeRoleBox', duration: 100}"
                                        @click="VerifyRoleChange(t.username, t.fullName, 'Teacher')" v-if="t.fullName!='Not Yet Invited'">
                                    Change Role
                                </button>
                                <button class="btn actionbtn" v-scroll-to="{el: '#deleteBox', duration: 100}"
                                        @click="RemoveUser(t.username, t.fullName)" v-if="t.fullName!='Not Yet Invited'">
                                    Remove
                                </button>
                            </td>
                        </tr>
                        <tr class="hdrrow">
                            <td>Leadership Team</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-for="l in leadershipTeam" :key="l.username">
                            <td></td>
                            <td>{{ l.fullName }}</td>
                            <td>{{ l.email }}</td>
                            <td>
                                <button class="btn actionbtn" v-scroll-to="{el: '#changeRoleBox', duration: 100}"
                                        @click="VerifyRoleChange(l.username, l.fullName, 'Leadership')" v-if="l.fullName!='Not Yet Invited'">
                                    Change Role
                                </button>
                                <button class="btn actionbtn" v-scroll-to="{el: '#deleteBox', duration: 100}"
                                        @click="RemoveUser(l.username, l.fullName)" v-if="l.fullName!='Not Yet Invited'">
                                    Remove
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />

        <div class="row warning ctr" v-show="showWarning">
            <p>{{ warningText }}</p>
        </div>
        <br />
        <div class="row notice ctr" v-if="showMessage">
            <p>{{ textMessage }}</p>
        </div>
        <br />
        <div id="deleteBox" class="row modalbox" v-if="showDelete">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Delete {{ fullName }}?</p>
                <button class="btn btn-yes verifybtn" @click="DeleteUser">Yes</button>
                <button class="btn btn-no verifybtn" @click="showDelete=false">Cancel</button>
            </div>
        </div>

        <div id="changeRoleBox" class="row modalbox" v-if="showChangeRole">
            <div class="col-sm-4 offset-sm-4 verifybox">
                <p class="ctr">Choose a new role for {{ fullName }}:</p>
                <div class="col-sm-4" style="display:inline-block;"></div>
                <div class="col-sm-4" style="display:inline-block;" v-bind:class="[(this.windowWidth < 572) ? 'lnudge' : '']">
                    <div class="form-check" v-if="changeFromRole!='Supervisor'">
                        <input class="form-check-input" type="radio" name="flexRadioChange" id="flexRadioChange1" @change="ChangeToSupervisor">
                        <label class="form-check-label" for="flexRadioChange1">
                            Supervisor
                        </label>
                    </div>
                    <div class="form-check" v-if="changeFromRole!='Teacher'">
                        <input class="form-check-input" type="radio" name="flexRadioChange" id="flexRadioChange2" @change="ChangeToTeacher">
                        <label class="form-check-label" for="flexRadioChange2">
                            Teacher
                        </label>
                    </div>
                    <div class="form-check" v-if="changeFromRole!='Leadership'">
                        <input class="form-check-input" type="radio" name="flexRadioChange" id="flexRadioChange3" @change="ChangeToLeadership">
                        <label class="form-check-label" for="flexRadioChange3">
                            Leadership Team
                        </label>
                    </div>
                </div>
                <br />
                <div class="ctr">
                    <button class="btn btn-yes verifybtn" @click="ChangeRole">Change</button>
                    &nbsp;
                    <button class="btn btn-no verifybtn" @click="ClearChangeValues">Cancel</button>
                </div>
            </div>
        </div>
        <br />
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Invite",
        data() {
            return {
                title: "Invite",
                inviteMessage: "",
                inviteEmail: "",
                inviteRole: "Teacher",
                changeToRole: "",
                changeFromRole: "",
                firstName: "",
                lastName: "",
                inviteFail: false,
                supervisor: [],
                teachers: [],
                leadershipTeam: [],
                showWarning: false,
                warningText: "",
                deleteUser: "",
                fullName: "",
                showDelete: false,
                showChangeRole: false,
                changeUser: "",
                textMessage: "",
                showMessage: false,
                windowWidth: 0,
                leaderToken: ""
            }
        },

        methods: {

            GetSupervisor() {
                axios.get('leader/getsupervisor', { params: { leaderName: this.$store.state.leaderAlias }})
                    .then((res) => {
                        this.supervisor = res.data;
                    })
            },

            GetTeachers() {
                axios.get('leader/getteachers', { params: { leaderName: this.$store.state.leaderAlias }})
                    .then((res) => {
                        if (res.data == "Not Yet Invited") {
                            this.teachers = [];
                            this.teachers.push({
                                fullName: "Not Yet Invited"
                            });
                        } else {
                            this.teachers = res.data;
                        }
                    })
            },

            GetLeadership() {
                axios.get('leader/getleadership', { params: { leaderName: this.$store.state.leaderAlias }})
                    .then((res) => {
                        if (res.data == "Not Yet Invited") {
                            this.leadershipTeam = [];
                            this.leadershipTeam.push({
                                fullName: "Not Yet Invited"
                            });
                        } else {
                            this.leadershipTeam = res.data;
                        }
                    })
            },

            GetToken() {
                axios.get('leader/gettoken', { params: { leaderName: this.$store.state.leaderAlias }})
                    .then((res) => {
                        this.leaderToken = res.data;
                    })
            },

            InviteUser() {
                this.showWarning = false;
                this.showDelete = false;
                this.showMessage = false;
                this.textMessage = "";
                if (this.ValidateInput() === true) {
                    var postData = {
                        "Role": this.inviteRole, "Email": this.inviteEmail, "FirstName": this.firstName, "LastName": this.lastName, "LeaderName": this.$store.state.leaderAlias
                    };
                    axios.post('account/createresponder', postData)
                        .then(() => {
                            switch (this.inviteRole) {
                                case "Supervisor":
                                    this.GetSupervisor();
                                    break;
                                case "Teacher":
                                    this.GetTeachers();
                                    break;
                                case "Leadership":
                                    this.GetLeadership();
                                    break;
                            }
                            this.inviteFail = false;
                            this.inviteMessage = "Invitation sent.";
                            this.ClearInput();
                        })
                        .catch((error) => {
                            this.inviteFail = true;
                            this.inviteMessage = error.response.data;
                        });
                }
            },

            ClearInput() {
                this.inviteEmail = "";
                this.firstName = "";
                this.lastName = "";
            },

            ClearChangeValues() {
                this.showChangeRole = false;
                this.showWarning = false;
                this.warningText = "";
            },

            VerifyRoleChange(username, fullName, activeRole) {
                this.changeUser = username;
                this.fullName = fullName;
                this.changeFromRole = activeRole;
                this.showChangeRole = true;
                this.showDelete = false;
                this.inviteMessage = "";
                this.warningText = "";
                this.showMessage = false;
                this.textMessage = "";
                this.changeToRole = "";
            },

            ChangeRole() {
                if (this.changeToRole == "") {
                    this.showWarning = true;
                    this.warningText = "You must select a role!";
                } else {
                    this.showWarning = false;
                    this.inviteFail = false;
                    this.inviteMessage = "";
                    this.showDelete = false;
                    this.showChangeRole = false;
                    var postData = {
                        "Username": this.changeUser, "Role": this.changeToRole,
                        "LeaderName": this.$store.state.leaderAlias == "" ? this.$store.state.leaderName : this.$store.state.leaderAlias
                    };
                    axios.post('leader/changerole', postData)
                        .then((res) => {
                            if (res.data == "Already completed survey.") {
                                this.showWarning = true;
                                this.warningText = "This participant has already completed their survey. Changing roles is not allowed.";
                            } else if (res.data == "Supervisor already exists.") {
                                this.showWarning = true;
                                this.warningText = "A supervisor has already been invited.";
                            } else {
                                this.showMessage = true;
                                this.textMessage = this.fullName + "'s role has been changed.";
                                this.GetSupervisor();
                                this.GetTeachers();
                                this.GetLeadership();
                            }
                            this.changeToRole = "";
                            this.changeFromRole = "";
                        });
                }
            },

            RemoveUser(username, fullName) {
                this.deleteUser = username;
                this.fullName = fullName;
                this.showDelete = true;
                this.showChangeRole = false;
                this.inviteMessage = "";
                this.warningText = "";
                this.showMessage = false;
                this.textMessage = "";
            },

            DeleteUser() {
                this.showWarning = false;
                this.inviteFail = false;
                this.inviteMessage = "";
                var postData = {
                    "Username": this.deleteUser, "LeaderName": this.$store.state.leaderAlias == "" ? this.$store.state.leaderName : this.$store.state.leaderAlias
                };
                axios.post('leader/deleteuser', postData)
                    .then((res) => {
                        if (res.data == "Already started survey.") {
                            this.showWarning = true;
                            this.warningText = "This participant has already begun their survey. Removing the participant is not allowed.";
                        } else {
                            this.GetSupervisor();
                            this.GetTeachers();
                            this.GetLeadership();
                        }
                        this.showDelete = false;
                    });
            },

            ValidateInput() {
                if (this.inviteEmail == "") {
                    this.inviteMessage = "You must input a valid email address.";
                    this.inviteFail = true;
                    return false;
                }

                if (this.firstName == "") {
                    this.inviteMessage = "You must input a first name.";
                    this.inviteFail = true;
                    return false;
                }

                if (this.lastName == "") {
                    this.inviteMessage = "You must input a last name.";
                    this.inviteFail = true;
                    return false;
                }

                this.inviteMessage = "";
                this.inviteFail = false;
                return true;
            },

            SelectSupervisor() {
                this.inviteRole = "Supervisor";
            },

            SelectTeacher() {
                this.inviteRole = "Teacher";
            },

            SelectLeadership() {
                this.inviteRole = "Leadership";
            },

            ChangeToSupervisor() {
                this.changeToRole = "Supervisor";
            },

            ChangeToTeacher() {
                this.changeToRole = "Teacher";
            },

            ChangeToLeadership() {
                this.changeToRole = "Leadership";
            },
        },

        mounted() {
            document.title = "Turnaround 360";

            this.windowWidth = window.innerWidth;

            window.onresize = () => {
                this.windowWidth = window.innerWidth;
            }

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetSupervisor();
                this.GetTeachers();
                this.GetLeadership();
                this.GetToken();
            }
        }
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .lnudge {
        margin-left: 90px;
    }

    input {
        margin-bottom: 16px;
    }

    .warning {
        color: #e60000;
    }

    .notice {
        color: #0d6efd;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    .invbtn {
        margin: 12px 66px 0 0;
    }

    .hdrrow {
        background-color: #e1e9ed;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .invitehdr {
        margin: 10px 0 5px 10px;
    }

    .invitebtn {
        background-color: #3771ab;
        color: #fcfcfc;
        width: 140px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
        font-size: 11px;
        margin-left: 10px;
        margin-bottom: 2px;
        width: 100px;
    }

    .buffer {
        margin-bottom: 4px;
    }

    .warning {
        color: #e60000;
    }

    .modalbox {
        margin-bottom: 100px;
    }

    .verifybtn {
        width: 75px;
        margin-right: 10px;
        margin-bottom: 3px;
        text-align: center;
    }

    .btn-yes {
        background-color: #33adff;
        color: #ffffff;
    }

    .btn-no {
        background-color: #850021;
        color: #ffffff;
    }

    .verifybox {
        border: 5px solid #000099;
        padding: 10px;
        background-color: #e6f7ff;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>