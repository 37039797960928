
<template>
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
    <br />
</template>

<script>
    export default {
        name: "ColumnChart",

        props: {
            selfscore: Number,
            superscore: Number,
            teacherscore: Number,
            leadershipscore: Number,
            compositescore: Number,
            title: String
        },

        data() {
            return {
                series: [{
                    data: [this.selfscore, this.superscore, this.teacherscore, this.leadershipscore, this.compositescore]
                }],

                chartOptions: {
                    chart: {
                        height: 500,
                        type: 'bar'
                    },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#660066'],
                    plotOptions: {
                        bar: {
                            columnWidth: '75%',
                            distributed: true,
                        }
                    },
                    legend: {
                        show: false
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            if (val > 0) { return val; }
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },
                    xaxis: {
                        categories: ["Self", "Supervisor", "Teachers", "Leadership Team", "Composite Score"],
                        labels: {
                            style: {
                                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#660066'],
                                fontSize: '12px'
                            }
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 8,
                        tickAmount: 8,
                        decimalsInFloat: 0
                    }
                }
            }
        },

        beforeUpdate() {
            this.series = [{
                data: [this.selfscore, this.superscore, this.teacherscore, this.leadershipscore, this.compositescore]
            }];

            this.chartOptions = {
                ...this.chartOptions, ...{
                    title: {
                        text: this.title
                    },
                }
            }
        }
    }
</script>