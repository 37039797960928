<template>
    <div id="wrapper">
        <div class="row tophdr">
            <div class="col-10 offset-1">
                <h3><strong>Contact Us</strong></h3>
            </div>
        </div>

        <div class="row subhdr">
            <div class="col-10 offset-1">
                <p>If you have technical questions, questions about the process and content of the survey, or if you have lost your password, please contact:</p>
                <ul>
                    <li class="manwithaplan"> Jimmy Hyde</li>
                    <li>Project Coordinator, Web Development</li>
                    <li>Education Service Center Region 13</li>
                    <li><PhoneIcon class="icon" aria-label="Phone Number" /> &nbsp;<a href="tel:15129195110">512-919-5110</a></li>
                    <li><MailIcon class="icon" aria-label="Email" /> &nbsp;<a href="mailto:jimmy.hyde@esc13.txed.net">jimmy.hyde@esc13.txed.net</a></li>
                </ul>
            </div>
        </div>
        <hr />

        <div class="row midsect">
            <div class="col-10 offset-1">
                <p>
                    The Turnaround Leader 360 Feedback Assessment was developed as a partnership between <a href="http://www4.esc13.net/" target="_blank">Education Service Center Region 13</a>
                    and <a href="http://publicimpact.com/" target="_blank">Public Impact</a>.
                </p><br />
                <div class="row">
                    <div class="col-sm-3">
                        <p>
                            <b>Erin Midgley Romero</b><br />
                            Director: Special Projects<br />
                            Education Service Center Region 13<br />
                        </p>
                    </div>
                    <div class="col-sm-3">
                        <p>
                            <b>Millie Klein</b><br />
                            Chief Deputy Executive Director<br />
                            Education Service Center Region 13<br />
                        </p>
                    </div>
                    <div class="col-sm-3">
                        <p>
                            <b>Tim Field</b><br />
                            Senior Consultant<br />
                            Public Impact<br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <br />
    </div>
    <br />
</template>

<script>
    import { PhoneIcon } from '@heroicons/vue/solid';
    import { MailIcon } from '@heroicons/vue/solid';

    export default {
        name: "About",
        components: {
            PhoneIcon,
            MailIcon
        },

        mounted() {
            document.title = "Turnaround 360";
        }
    }
</script>

<style scoped>
    .tophdr {
        margin-top: 24px;
        margin-bottom: 20px;
    }

    .subhdr {
        background-color: #e1e9ed;
        padding-top: 18px;
    }

    .ctr {
        text-align: center;
    }

    .midsect {
        background-color: #cce0ea;
        padding-top: 12px;
    }

    .manwithaplan {
        font-weight: bold;
    }

    .icon {
        height: 18px;
        width: auto;
    }

    ul {
        list-style: none;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>