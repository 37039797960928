<template>
    <div id="wrapper" class="container-fluid">
        <div class="subhdr">
            <h1 class="ctr">Send Initial Invites</h1>
            <h3 class="ctr">to Alternate Address</h3>
            <br />
            <div class="row">
                <div class="col-6 offset-3 col-lg-4 offset-lg-4">
                    <form method="post">
                        <div class="form-group">
                            <label for="searchUser" class="lbl"><strong>Enter a username or email address to find an account:</strong></label>
                            <input name="searchUser" type="text" v-model="searchUser" class="form-control" placeholder="Username or Email" aria-label="Username or Email"
                                   v-bind:class="[(searchUserFail && (searchMessage=='You must input a valid username or email address.' || searchMessage=='Could not find user.')) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <p class="ctr"><button type="submit" class="btn btn-lg actionbtn" @click.prevent="UserSearch">Search</button></p>
                        <br />
                        <div class="form-group">
                            <label for="altEmail" class="lbl"><strong>Enter email address to send to:</strong></label>
                            <input name="altEmail" type="text" v-model="altEmail" class="form-control" placeholder="Alternate Email Address" aria-label="Alternate Email Address"
                                   v-bind:class="[altEmailFail ? 'warnstyle' : 'normalstyle']"/>
                        </div>
                        <br />
                    </form>
                    <p class="ctr vspace" v-show="searchMessage.length>0" v-bind:class="[(searchUserFail==true) ? 'warning' : 'notice']">{{ searchMessage }}</p>
                </div>
            </div>
        </div>

        <div class="row">
            <p class="ctr vspace" v-bind:class="[(this.manageFail==true || this.altEmailFail==true) ? 'warning' : 'notice']" v-show="manageMessage.length>0">{{ manageMessage }}</p>
        </div>

        <div class="row userlist" v-show="userData.length > 0">
            <div class="col-sm-11 offset-sm-1 table-responsive">
                <table v-bind:class="[(this.windowWidth <= 1300) ? 'smalltable' : '']">
                    <colgroup>
                        <col style="width:13%">
                        <col style="width:12%">
                        <col style="width:13%">
                        <col style="width:22%">
                        <col style="width:14%">
                        <col style="width:14%">
                        <col style="width:12%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Purchaser / Leader</th>
                            <th scope="col">Account Created</th>
                            <th scope="col">&nbsp;&nbsp;&nbsp; Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in userData" :key="user.username">
                            <td>{{ user.fullName }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ user.username }}</td>
                            <td>{{ user.email }}</td>
                            <td v-if="user.role=='Leader'">{{ user.purchaser }}</td>
                            <td v-else>{{ user.leader }}</td>
                            <td>{{ user.dateCreated }}</td>
                            <td>
                                <button class="btn btn-sm actionbtn leftbtn" v-if="user.role!=='Admin'" @click="RedirectInvite(user.username, user.fullName)">
                                    Send Invite
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Admin",
        data() {
            return {
                title: "Admin",
                searchUser: "",
                createEmail: "",
                seats: "",
                firstname: "",
                lastname: "",
                school: "",
                district: "",
                selectedState: "NS",
                searchMessage: "",
                purchaserMessage: "",
                searchUserFail: false,
                purchaserFail: false,
                userData: [],
                activeUsername: "",
                activeFullName: "",
                showReset: false,
                showDelete: false,
                showSeats: false,
                seatsRemaining: 0,
                seatsPurchased: 0,
                newSeatCount: 0,
                manageMessage: "",
                manageFail: false,
                newSessionSeats: 0,
                showNewSession: false,
                showEmailSend: false,
                showForce: false,
                windowWidth: 0,
                altEmail: "",
                altEmailFail: false
            }
        },

        methods: {
            UserSearch() {
                this.ClearWarnings();
                if (this.searchUser != "") {
                    axios.get('admin/findusers', { params: { searchUser: this.searchUser }})
                        .then((res) => {
                            this.userData = res.data;
                            this.$store.state.searchUser = this.searchUser;
                    })
                    .catch((error) => {
                        this.searchMessage = error.response.data;
                        this.searchUserFail = true;
                    });
                } else {
                    this.searchMessage = "You must input a valid username or email address.";
                    this.searchUserFail = true;
                    this.$store.state.searchUser = "";
                    this.userData = "";
                }
            },

            RedirectInvite(username, fullName) {
                this.ClearWarnings();

                if (this.altEmail == "") {
                    this.manageMessage = "You must enter an email address to send to.";
                    this.altEmailFail = true;
                } else {
                    var postData = { "Username": username, "Email": this.altEmail };
                    axios.post('account/redirectinvite', postData)
                        .then(() => {
                            this.manageMessage = "The invite for " + fullName + " has been recreated.";
                        })
                        .catch((error) => {
                            this.manageMessage = error.response.data;
                            this.manageFail = true;
                        });
                }
            },

            ClearWarnings() { // this.searchUser should NOT be initialized in this function
                this.searchMessage = "",
                this.purchaserMessage = "";
                this.searchUserFail = false;
                this.purchaserFail = false;
                this.activeUsername = "";
                this.activeFullName = "";
                this.showReset = false;
                this.showDelete = false;
                this.showSeats = false;
                this.showEmailSend = false;
                this.showForce = false;
                this.seatsPurchased = 0;
                this.seatsRemaining = 0;
                this.newSeatCount = 0;
                this.manageMessage = "";
                this.manageFail = false;
                this.showNewSession = false;
                this.newSessionSeats = 0;
                this.altEmailFail = false;
            }
        },

        mounted() {
            document.title = "Turnaround 360";

            this.windowWidth = window.innerWidth;

            window.onresize = () => {
                this.windowWidth = window.innerWidth;
            }
        }
    }
</script>

<style scoped>
    button {
        margin-bottom: 8px;
    }

    .ctr {
        text-align: center;
    }

    .lbl {
        margin-bottom: 8px;
    }

    button {
        background-color: #0d6efd;
        border-color: transparent;
        color: #ffffff;
        margin-right: 8px;
    }

    button:hover {
        background-color: #68a4fd;
        border-color: #ab935c;
        border-width: 1px;
    }

    .warning {
        color: #e60000;
    }

    .notice {
        color: #0d6efd;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    label {
        text-align: left;
    }

    input {
        margin-bottom: 8px;
    }

    .vsize {
        width: 100%;
        height: 42px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
        margin-top: 6px;
        margin-left: 20px;
    }

    .leftbtn {
        width: 130px;
    }

    .verifybtn {
        width: 75px;
        margin-left: 10px;
    }

    .seatbtn {
        width: 150px;
        margin-left: 10px;
    }

    .createbtn {
        width: 175px;
    }

    .btn-yes {
        background-color: #0099ff;
    }

    .btn-no {
        background-color: #850021;
    }

    .verifybox {
        border: 5px solid #000099;
        padding: 10px;
        background-color: #e6f7ff;
    }

    .vspace {
        margin-top: 22px;
    }

    .userselect {
        color: #0039e6;
        cursor: pointer;
    }

    .seatinput {
        margin-left: 15px;
        width: 50px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .userlist {
        background-color: #cce0ea;
        margin: 25px 0;
    }

    .newacct {
        background-color: #e1e9ed;
        padding: 8px;
        margin-top: 20px;
    }

    .smalltable {
        width: 1400px;
    }

    tr {
        border-bottom: solid 1px grey;
    }

    tr:last-child {
            border-bottom: none;
        }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>