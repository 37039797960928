<template>
    <div id="wrapper">
        <div class="subhdr">
            <br />
            <h1 class="ctr">Account Access</h1>
            <br />
            <div class="row">
                <div class="col-8 col-sm-6 col-md-6 col-lg-4 offset-2 offset-sm-3 offset-md-3 offset-lg-4">
                    <form method="post" class="frmstyle">
                        <div class="form-group">
                            <input name="Email" type="text" v-model="email" class="form-control" placeholder="Email" aria-label="Email"
                                   v-bind:class="[(this.emailFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <div class="form-group">
                            <input name="Token" type="password" v-model="token" class="form-control" placeholder="Token" aria-label="Token"
                                   v-bind:class="[(this.tokenFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <button type="submit" class="btn btn-large actionbtn ctr" @click.prevent="Submitted">Get Login Credentials</button>
                    </form>
                    <br />
                    <p class="warning ctr" v-if="!success">{{ warning }}</p>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div v-if="showInfo" class="ctr">
            <p>Your new username is: <strong>{{ userName }}</strong></p>
            <p>Your new password is: <strong>{{ password }}</strong></p>
            <p>Please make note of your username and password and secure them in a safe place.</p>
        </div>
        <div v-else class="col-6 offset-3">
            <p>
                <strong>
                    If you have been invited to be a participant in the T360 system but did not receive a welcome email with your username and password, you may have received a token instead.
                    You can enter your email address and token here to get your new login credentials. The email address must match the address your leader or administrator signed you up with.
                </strong>
            </p>
        </div>
    </div>
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Signup",

        data() {
            return {
                title: "Signup",
                email: "",
                token: "",
                success: true,
                warning: "Login failed. Please try again.",
                emailFail: false,
                tokenFail: false,
                userRole: "",
                userName: "",
                password: "",
                showInfo: false
            }
        },

        methods: {
            Submitted() {
                if (this.email != "") {
                    this.emailFail = false;
                    if (this.token != "") {
                        this.tokenFail = false;
                        var postData = { "Username": this.email, "Password": this.token };
                        axios.post('account/signup', postData)
                            .then((res) => {
                                this.userName = res.data[0];
                                this.password = res.data[1];
                                this.showInfo = true;
                                this.success = true;
                            })
                            .catch((error) => {
                                this.success = false;
                                this.warning = error.response.data;
                            });
                    } else {
                        this.warning = "You must enter a token.";
                        this.tokenFail = true;
                        this.success = false;
                    }
                } else {
                    this.warning = "You must enter a valid email address.";
                    this.emailFail = true;
                    this.success = false;
                }
            }
        },

        mounted() {
            document.title = "Turnaround 360";
        },
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .signup {
        color: #c72d0d;
    }

    .frmstyle {
        text-align: center;
    }

    button {
        background-color: #0d6efd;
        border-color: transparent;
        color: #ffffff;
    }

    button:hover {
        background-color: #68a4fd;
        border-color: #ab935c;
        border-width: 1px;
    }

    .warning {
        color: #e60000;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .subhdr {
        background-color: #add8e6;
        padding: 8px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>