<template>
    <div class="row d-none d-sm-block">
        <div class="col-sm-3 offset-sm-3">
            <ring-loader :loading="isLoading" :color="color" :size="4000"></ring-loader>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8 offset-sm-2">
            <h2 class="nudge">Turnaround Leader 360 Feedback Report</h2>

            <div class="d-none d-sm-block">
                <button class="btn btn-large printbtn noprint" @click="PrintReport" v-show="!isLoading">Print</button>
            </div>

            <h5 class="nudge">Leader: {{ leaderFullName }} </h5>
            <h4 class="nudge">Introduction to Your Turnaround Leader 360 Feedback Report</h4>
            <p class="nudge">
                The turnaround leader 360 feedback tool is designed to provide formative, development feedback on
                the extent to which you have demonstrated research-based
                <a href='#appendix1'>turnaround leader actions and turnaround leader competencies</a>.
                This report is based entirely on the responses to the online questionnaire and is
                primarily organized by turnaround leader action clusters. The six action clusters collectively include the
                14 leader actions identified through cross-sector research on successful turnarounds. Each action
                cluster is associated with a set of three primary leader competencies. These underlying competencies
                are traits that support the consistent implementation of the turnaround leader actions.<br />

                This report provides different views of the survey response data, including responses to Likert scale
                questions and free-response, narrative comments. The report provides average scores across leader
                action clusters and competencies and also provides data on the specific questions from the survey. You
                will also find average responses broken down by the respondent groups (e.g. supervisor, self, staff,
                leadership team members). To preserve anonymity, at least three raters are required within each
                respondent group in order to present results separately. Responses to narrative questions are included
                verbatim in the report and often provide insightful, concrete examples of the leader actions and
                competencies assessed in the quantitative scores.
            </p>
            <br />
            <h4 class="nudge">Overview of Report Contents</h4>
            <table class="table table-striped">
                <colgroup>
                    <col style="width:10%">
                    <col style="width:25%">
                    <col style="width:65%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Page</th>
                        <th scope="col">Report View</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a href="#clusOver">2</a></td>
                        <td>Overview of Leader Action Cluster Scores</td>
                        <td>Composite ratings for each of the six turnaround leader action clusters by respondent group</td>
                    </tr>
                    <tr>
                        <td><a href="#clusRats">3-8</a></td>
                        <td>Ratings on Leader Action Cluster Scores</td>
                        <td>Composite ratings for specific survey questions by turnaround leader action cluster</td>
                    </tr>
                    <tr>
                        <td><a href="#compRats">9-10</a></td>
                        <td>Ratings on Primary Leadership Competencies</td>
                        <td>Composite ratings for turnaround leadership competency survey questions</td>
                    </tr>
                    <tr>
                        <td><a href="#stOps">11-12</a></td>
                        <td>Strengths and Development Opportunities</td>
                        <td>Top 5 highest and lowest rated turnaround leader actions, based on average scores across all respondents</td>
                    </tr>
                    <tr>
                        <td><a href="#unexpect">13</a></td>
                        <td>Unexpected Strengths and Blind Spots</td>
                        <td>Top rated actions (strengths) and low rated actions (blind spots) where your self-assessment rating was significantly higher or lower than your overall score</td>
                    </tr>
                    <tr>
                        <td><a href="#narResp">14+</a></td>
                        <td>Narrative Responses</td>
                        <td>Verbatim responses to narrative questions on top 3 leadership strengths and development opportunities</td>
                    </tr>
                    <tr>
                        <td><a href="#appendix1">A.1</a></td>
                        <td>Appendix</td>
                        <td>School Turnaround Leader Competencies</td>
                    </tr>
                    <tr>
                        <td><a href="#appendix2">A.2</a></td>
                        <td>Appendix</td>
                        <td>School Turnaround Leader Actions</td>
                    </tr>
                    <tr>
                        <td><a href="#appendix3">A.3</a></td>
                        <td>Appendix</td>
                        <td>Turnaround Leader Action Clusters and Primary Competencies</td>
                    </tr>
                    <tr>
                        <td><a href="#appendix4">A.4</a></td>
                        <td>Appendix</td>
                        <td>Turnaround Leader 360 Participants</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <h4 class="nudge">Personal Development Plan</h4>
            <p class="nudge">
                For maximum benefit, use the feedback from this report to create your own development plan.
                The <span class="clickable" @click="GetFile('ParticipantWorkbook.pdf')">Turnaround Leader 360 Feedback Assessment: Personal Development Plan Workbook</span>
                provides more detailed guidance to create your personalized plan, but key steps include:<br />
                <ol>
                    <li>Identify personal development goals based on the insights from the 360 report and, as applicable, input from your supervisor and/or leadership coach.</li>
                    <li>For each goal, identify specific steps to take.  For instance: work with your supervisor or coach, relevant training, selected reading, practicing new behaviors, requesting ongoing feedback.</li>
                    <li>Identify markers that will help you check your progress.</li>
                </ol>
                A leadership coach or your supervisor can play a valuable role in helping you to establish and
                follow through with your development plan.
            </p>
        </div>
    </div>
    <br />
    <br />

    <div class="row printbreak" id="clusOver">
        <div class="col-sm-11 titlebuffer">
            <div class="sectionhdr"><h3 class="nudge">Overview of Turnaround Leader Action Cluster Scores (by Respondent Group)</h3></div>
            <br />
        </div>
    </div>

    <div class="row buffer">
        <div class="col-sm-4">
            <span class="barTitle">Focus on a Few Early Wins</span>
            <BarChart :selfscore="A4self" :superscore="A4super" :teacherscore="A4teachers" :leadershipscore="A4leadership" :compositescore="A4composite" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Lead a Turnaround Campaign</span>
            <BarChart :selfscore="B4self" :superscore="B4super" :teacherscore="B4teachers" :leadershipscore="B4leadership" :compositescore="B4composite" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Get the Right Staff; Right the Remainder</span>
            <BarChart :selfscore="C4self" :superscore="C4super" :teacherscore="C4teachers" :leadershipscore="C4leadership" :compositescore="C4composite" />
        </div>
    </div>

    <div class="row buffer">
        <div class="col-sm-4">
            <span class="barTitle">Drive Decisions with Open-Air Data</span>
            <BarChart :selfscore="D4self" :superscore="D4super" :teacherscore="D4teachers" :leadershipscore="D4leadership" :compositescore="D4composite" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Break Organizational Norms</span>
            <BarChart :selfscore="E4self" :superscore="E4super" :teacherscore="E4teachers" :leadershipscore="E4leadership" :compositescore="E4composite" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Do What Works; Raise the Bar</span>
            <BarChart :selfscore="F4self" :superscore="F4super" :teacherscore="F4teachers" :leadershipscore="F4leadership" :compositescore="F4composite" />
        </div>
    </div>

    <!-- Ratings on Turnaround Leader Action Cluster Scores -->
    <!-- Focus on a Few Early Wins (A) -->
    <div class="row printbreak" id="clusRats">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="sectionhdr"><h3 class="nudge">Ratings on Turnaround Leader Action Cluster Scores</h3></div>
            <div class="charthdr"><h4 class="nudge">Focus on a Few Early Wins</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="sectionhdr"><h3 class="nudge">Ratings on Turnaround Leader Action Cluster Scores</h3></div>
            <div class="charthdr"><h4 class="nudge">Focus on a Few Early Wins</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Focus on a Few Early Wins" :selfscore="A4self"
                         :superscore="A4super" :teacherscore="A4teachers" :leadershipscore="A4leadership" :compositescore="A4composite" />
        </div>
    </div>

    <!-- mobile version of charts (A) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n-1] }}</span>
                <BarChart :selfscore="selfScores[n-1]*1" :superscore="superScores[n-1]*1" :teacherscore="teacherScores[n-1]*1"
                          :leadershipscore="leadershipScores[n-1]*1" :compositescore="compositeScores[n-1]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">{{ qText[3] }}</span>
                <BarChart :selfscore="selfScores[3]*1" :superscore="superScores[3]*1" :teacherscore="teacherScores[3]*1"
                          :leadershipscore="leadershipScores[3]*1" :compositescore="compositeScores[3]*1" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">{{ qText[4] }}</span>
                <BarChart :selfscore="selfScores[4]*1" :superscore="superScores[4]*1" :teacherscore="teacherScores[4]*1"
                          :leadershipscore="leadershipScores[4]*1" :compositescore="compositeScores[4]*1" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span>
                <BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Planning Ahead* (anticipates obstacles; seizes opportunities)</span>
                <BarChart :selfscore="A2self" :superscore="A2super" :teacherscore="A2teachers" :leadershipscore="A2leadership" :compositescore="A2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span>
                <BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (A) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n-1] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n-1]*1" :superscore="superScores[n-1]*1" :teacherscore="teacherScores[n-1]*1"
                                  :leadershipscore="leadershipScores[n-1]*1" :compositescore="compositeScores[n-1]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">{{ qText[3] }}</span></td>
                    <td><span class="barTitle">{{ qText[4] }}</span></td>
                    <td><span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span></td>
                </tr>
                <tr>
                    <td>
                        <BarChart :selfscore="selfScores[3]*1" :superscore="superScores[3]*1" :teacherscore="teacherScores[3]*1"
                                  :leadershipscore="leadershipScores[3]*1" :compositescore="compositeScores[3]*1" />
                    </td>
                    <td>
                        <BarChart :selfscore="selfScores[4]*1" :superscore="superScores[4]*1" :teacherscore="teacherScores[4]*1"
                                  :leadershipscore="leadershipScores[4]*1" :compositescore="compositeScores[4]*1" />
                    </td>
                    <td>
                        <BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Planning Ahead* (anticipates obstacles; seizes opportunities)</span></td>
                    <td><span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span></td>
                    <td></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="A2self" :superscore="A2super" :teacherscore="A2teachers" :leadershipscore="A2leadership" :compositescore="A2composite" /></td>
                    <td><BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" /></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Lead a Turnaround Campaign (B) -->
    <div class="row printbreak">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="charthdr"><h4 class="nudge">Lead a Turnaround Campaign</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="charthdr"><h4 class="nudge">Lead a Turnaround Campaign</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Lead a Turnaround Campaign" :selfscore="B4self"
                         :superscore="B4super" :teacherscore="B4teachers" :leadershipscore="B4leadership" :compositescore="B4composite" />
        </div>
    </div>

    <!-- mobile version of charts (B) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+4] }}</span>
                <BarChart :selfscore="selfScores[n+4]*1" :superscore="superScores[n+4]*1" :teacherscore="teacherScores[n+4]*1"
                          :leadershipscore="leadershipScores[n+4]*1" :compositescore="compositeScores[n+4]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+7] }}</span>
                <BarChart :selfscore="selfScores[n+7]*1" :superscore="superScores[n+7]*1" :teacherscore="teacherScores[n+7]*1"
                          :leadershipscore="leadershipScores[n+7]*1" :compositescore="compositeScores[n+7]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span>
                <BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span>
                <BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Self Confidence* (demonstrates confidence reaching goals; shows conviction in decisions)</span>
                <BarChart :selfscore="B3E2self" :superscore="B3E2super" :teacherscore="B3E2teachers" :leadershipscore="B3E2leadership" :compositescore="B3E2composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (B) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+4] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+4]*1" :superscore="superScores[n+4]*1" :teacherscore="teacherScores[n+4]*1"
                                  :leadershipscore="leadershipScores[n+4]*1" :compositescore="compositeScores[n+4]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+7] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+7]*1" :superscore="superScores[n+7]*1" :teacherscore="teacherScores[n+7]*1"
                                  :leadershipscore="leadershipScores[n+7]*1" :compositescore="compositeScores[n+7]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span></td>
                    <td><span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span></td>
                    <td><span class="barTitle">Leadership Competency: Self Confidence* (demonstrates confidence reaching goals; shows conviction in decisions)</span></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" /></td>
                    <td><BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" /></td>
                    <td><BarChart :selfscore="B3E2self" :superscore="B3E2super" :teacherscore="B3E2teachers" :leadershipscore="B3E2leadership" :compositescore="B3E2composite" /></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Get the Right Staff; Right the Remainder (C) -->
    <div class="row printbreak">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="charthdr"><h4 class="nudge">Get the Right Staff; Right the Remainder</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="charthdr"><h4 class="nudge">Get the Right Staff; Right the Remainder</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Get Right Staff; Right the Remainder" :selfscore="C4self"
                         :superscore="C4super" :teacherscore="C4teachers" :leadershipscore="C4leadership" :compositescore="C4composite" />
        </div>
    </div>

    <!-- mobile version of charts (C) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+10] }}</span>
                <BarChart :selfscore="selfScores[n+10]*1" :superscore="superScores[n+10]*1" :teacherscore="teacherScores[n+10]*1"
                          :leadershipscore="leadershipScores[n+10]*1" :compositescore="compositeScores[n+10]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+13] }}</span>
                <BarChart :selfscore="selfScores[n+13]*1" :superscore="superScores[n+13]*1" :teacherscore="teacherScores[n+13]*1"
                          :leadershipscore="leadershipScores[n+13]*1" :compositescore="compositeScores[n+13]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span>
                <BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span>
                <BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span>
                <BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (C) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+10] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+10]*1" :superscore="superScores[n+10]*1" :teacherscore="teacherScores[n+10]*1"
                                  :leadershipscore="leadershipScores[n+10]*1" :compositescore="compositeScores[n+10]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+13] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+13]*1" :superscore="superScores[n+13]*1" :teacherscore="teacherScores[n+13]*1"
                                  :leadershipscore="leadershipScores[n+13]*1" :compositescore="compositeScores[n+13]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span></td>
                    <td><span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span></td>
                    <td><span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" /></td>
                    <td><BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" /></td>
                    <td><BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" /></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Drive Decisions with Open-Air Data (D) -->
    <div class="row printbreak">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="charthdr"><h4 class="nudge">Drive Decisions with Open-Air Data</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="charthdr"><h4 class="nudge">Drive Decisions with Open-Air Data</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Drive Decisions with Open-Air Data" :selfscore="D4self"
                         :superscore="D4super" :teacherscore="D4teachers" :leadershipscore="D4leadership" :compositescore="D4composite" />
        </div>
    </div>

    <!-- mobile version of charts (D) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+16] }}</span>
                <BarChart :selfscore="selfScores[n+16]*1" :superscore="superScores[n+16]*1" :teacherscore="teacherScores[n+16]*1"
                          :leadershipscore="leadershipScores[n+16]*1" :compositescore="compositeScores[n+16]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+19] }}</span>
                <BarChart :selfscore="selfScores[n+19]*1" :superscore="superScores[n+19]*1" :teacherscore="teacherScores[n+19]*1"
                          :leadershipscore="leadershipScores[n+19]*1" :compositescore="compositeScores[n+19]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span>
                <BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span>
                <BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span>
                <BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (D) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+16] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+16]*1" :superscore="superScores[n+16]*1" :teacherscore="teacherScores[n+16]*1"
                                  :leadershipscore="leadershipScores[n+16]*1" :compositescore="compositeScores[n+16]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+19] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+19]*1" :superscore="superScores[n+19]*1" :teacherscore="teacherScores[n+19]*1"
                                  :leadershipscore="leadershipScores[n+19]*1" :compositescore="compositeScores[n+19]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span></td>
                    <td><span class="barTitle">Leadership Competency: Team Leadership* (promotes teamwork and collaboration; obtains resources)</span></td>
                    <td><span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" /></td>
                    <td><BarChart :selfscore="B2C3D2self" :superscore="B2C3D2super" :teacherscore="B2C3D2teachers" :leadershipscore="B2C3D2leadership" :compositescore="B2C3D2composite" /></td>
                    <td><BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" /></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Break Organizational Norms (E) -->
    <div class="row printbreak">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="charthdr"><h4 class="nudge">Break Organizational Norms</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="charthdr"><h4 class="nudge">Break Organizational Norms</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Break Organizational Norms" :selfscore="E4self"
                         :superscore="E4super" :teacherscore="E4teachers" :leadershipscore="E4leadership" :compositescore="E4composite" />
        </div>
    </div>

    <!-- mobile version of charts (E) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+22] }}</span>
                <BarChart :selfscore="selfScores[n+22]*1" :superscore="superScores[n+22]*1" :teacherscore="teacherScores[n+22]*1"
                          :leadershipscore="leadershipScores[n+22]*1" :compositescore="compositeScores[n+22]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span>
                <BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Self Confidence* (demonstrates confidence reaching goals; shows conviction in decisions)</span>
                <BarChart :selfscore="B3E2self" :superscore="B3E2super" :teacherscore="B3E2teachers" :leadershipscore="B3E2leadership" :compositescore="B3E2composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span>
                <BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (E) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+22] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+22]*1" :superscore="superScores[n+22]*1" :teacherscore="teacherScores[n+22]*1"
                                  :leadershipscore="leadershipScores[n+22]*1" :compositescore="compositeScores[n+22]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span></td>
                    <td><span class="barTitle">Leadership Competency: Self Confidence* (demonstrates confidence reaching goals; shows conviction in decisions)</span></td>
                    <td><span class="barTitle">Leadership Competency: Impact and Influence* (adapts communication to influence others; positively shifts attitudes)</span></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" /></td>
                    <td><BarChart :selfscore="B3E2self" :superscore="B3E2super" :teacherscore="B3E2teachers" :leadershipscore="B3E2leadership" :compositescore="B3E2composite" /></td>
                    <td><BarChart :selfscore="B1C2E3self" :superscore="B1C2E3super" :teacherscore="B1C2E3teachers" :leadershipscore="B1C2E3leadership" :compositescore="B1C2E3composite" /></td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Do What Works; Raise the Bar (F) -->
    <div class="row printbreak">
        <br />
        <div class="d-block d-sm-none col-sm-11">
            <div class="charthdr"><h4 class="nudge">Do What Works; Raise the Bar</h4></div>
            <br />
        </div>
        <div class="d-none d-sm-block col-sm-11 titlebuffer">
            <div class="charthdr"><h4 class="nudge">Do What Works; Raise the Bar</h4></div>
            <br />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <ColumnChart title="Composite Ratings for Do What Works; Raise the Bar" :selfscore="F4self"
                         :superscore="F4super" :teacherscore="F4teachers" :leadershipscore="F4leadership" :compositescore="F4composite" />
        </div>
    </div>

    <!-- mobile version of charts (F) -->
    <div class="d-block d-sm-none">
        <div class="row buffer">
            <div class="col-sm-4" v-for="n in 3" v-bind:key="n">
                <span class="barTitle">{{ qText[n+25] }}</span>
                <BarChart :selfscore="selfScores[n+25]*1" :superscore="superScores[n+25]*1" :teacherscore="teacherScores[n+25]*1"
                          :leadershipscore="leadershipScores[n+25]*1" :compositescore="compositeScores[n+25]*1" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">{{ qText[29] }}</span>
                <BarChart :selfscore="selfScores[29]*1" :superscore="superScores[29]*1" :teacherscore="teacherScores[29]*1"
                          :leadershipscore="leadershipScores[29]*1" :compositescore="compositeScores[29]*1" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span>
                <BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" />
            </div>
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span>
                <BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" />
            </div>
        </div>

        <div class="row buffer">
            <div class="col-sm-4">
                <span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span>
                <BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" />
            </div>
        </div>
    </div>

    <!-- desktop version of charts (F) -->
    <div class="row buffer col-sm-10 d-none d-sm-block printbreak">
        <table class="table">
            <colgroup>
                <col style="width:33%">
                <col style="width:33%">
                <col style="width:34%">
            </colgroup>
            <tbody>
                <tr class="rmvline">
                    <td v-for="n in 3" v-bind:key="n">
                        <span class="barTitle">{{ qText[n+25] }}</span>
                    </td>
                </tr>
                <tr>
                    <td v-for="n in 3" v-bind:key="n">
                        <BarChart :selfscore="selfScores[n+25]*1" :superscore="superScores[n+25]*1" :teacherscore="teacherScores[n+25]*1"
                                  :leadershipscore="leadershipScores[n+25]*1" :compositescore="compositeScores[n+25]*1" />
                    </td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">{{ qText[29] }}</span></td>
                    <td><span class="barTitle">Leadership Competency: Achievement* (sets challenging goals; takes personal risks)</span></td>
                    <td><span class="barTitle">Leadership Competency: Monitoring & Directiveness* (assigns clear responsibilities; manages staff performance)</span></td>
                </tr>
                <tr>
                    <td><BarChart :selfscore="selfScores[29]*1" :superscore="superScores[29]*1" :teacherscore="teacherScores[29]*1" :leadershipscore="leadershipScores[29]*1" :compositescore="compositeScores[29]*1" /></td>
                    <td><BarChart :selfscore="A1E1F1self" :superscore="A1E1F1super" :teacherscore="A1E1F1teachers" :leadershipscore="A1E1F1leadership" :compositescore="A1E1F1composite" /></td>
                    <td><BarChart :selfscore="C1D1F2self" :superscore="C1D1F2super" :teacherscore="C1D1F2teachers" :leadershipscore="C1D1F2leadership" :compositescore="C1D1F2composite" /></td>
                </tr>
                <tr class="rmvline">
                    <td><span class="barTitle">Leadership Competency: Conceptual and Analytical Thinking* (uses data to identify solutions / logical next steps)</span></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr class="rmvline">
                    <td><BarChart :selfscore="A3D3F3self" :superscore="A3D3F3super" :teacherscore="A3D3F3teachers" :leadershipscore="A3D3F3leadership" :compositescore="A3D3F3composite" /></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- END Ratings on Turnaround Leader Action Cluster Scores -->

    <div class="row printbreak" id="compRats">
        <div class="col-sm-10 offset-sm-1">
            <div class="sectionhdr"><h3 class="nudge">Ratings on Primary Turnaround Leader Competencies</h3></div>
            <br />
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8 offset-sm-1 ctr">
            <CompositeChart title="Composite Ratings for Turnaround Leader Competencies" :achvmt="Achvmt" :cat="CAT" :planahd="PlanAhd" :mad="MaD"
                            :iai="IaI" :tmldrshp="TmLdrshp" :selfcon="SelfCon" />
        </div>
    </div>

    <div class="row buffer">
        <div class="col-sm-2 offset-sm-1">
            <h4>Achievement</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Sets challenging goals for self and consistently works hard to meet them</span>
            <BarChart :selfscore="selfScores[30]*1" :superscore="superScores[30]*1" :teacherscore="teacherScores[30]*1" :leadershipscore="leadershipScores[30]*1" :compositescore="compositeScores[30]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Takes significant personal risks to launch new and challenging improvement efforts</span>
            <BarChart :selfscore="selfScores[31]*1" :superscore="superScores[31]*1" :teacherscore="teacherScores[31]*1" :leadershipscore="leadershipScores[31]*1" :compositescore="compositeScores[31]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer printbreak">
        <div class="col-sm-2 offset-sm-1">
            <h4>Conceptual and Analytical Thinking</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Considers multiple sources to identify solutions to complex problems</span>
            <BarChart :selfscore="selfScores[32]*1" :superscore="superScores[32]*1" :teacherscore="teacherScores[32]*1" :leadershipscore="leadershipScores[32]*1" :compositescore="compositeScores[32]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Identifies and communicates logical next steps to implement initiatives</span>
            <BarChart :selfscore="selfScores[33]*1" :superscore="superScores[33]*1" :teacherscore="teacherScores[33]*1" :leadershipscore="leadershipScores[33]*1" :compositescore="compositeScores[33]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer">
        <div class="col-sm-2 offset-sm-1">
            <h4>Planning Ahead</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Anticipates problems that may occur in the future and acts in advance to prevent them from happening</span>
            <BarChart :selfscore="selfScores[34]*1" :superscore="superScores[34]*1" :teacherscore="teacherScores[34]*1" :leadershipscore="leadershipScores[34]*1" :compositescore="compositeScores[34]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Identifies future opportunities and plans in advance to take advantage</span>
            <BarChart :selfscore="selfScores[35]*1" :superscore="superScores[35]*1" :teacherscore="teacherScores[35]*1" :leadershipscore="leadershipScores[35]*1" :compositescore="compositeScores[35]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer">
        <div class="col-sm-2 offset-sm-1">
            <h4>Monitoring and Directiveness</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Assigns clear responsibility for tasks and decisions, setting clear objectives and measures</span>
            <BarChart :selfscore="selfScores[36]*1" :superscore="superScores[36]*1" :teacherscore="teacherScores[36]*1" :leadershipscore="leadershipScores[36]*1" :compositescore="compositeScores[36]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Deals proactively with staff performance problems</span>
            <BarChart :selfscore="selfScores[37]*1" :superscore="superScores[37]*1" :teacherscore="teacherScores[37]*1" :leadershipscore="leadershipScores[37]*1" :compositescore="compositeScores[37]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer printbreak">
        <div class="col-sm-2 offset-sm-1">
            <h4>Impact and Influence</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Adapts communication style and methods to influence individuals and groups</span>
            <BarChart :selfscore="selfScores[38]*1" :superscore="superScores[38]*1" :teacherscore="teacherScores[38]*1" :leadershipscore="leadershipScores[38]*1" :compositescore="compositeScores[38]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Demonstrates the ability to positively shift the attitudes and beliefs of staff and school community members</span>
            <BarChart :selfscore="selfScores[39]*1" :superscore="superScores[39]*1" :teacherscore="teacherScores[39]*1" :leadershipscore="leadershipScores[39]*1" :compositescore="compositeScores[39]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer">
        <div class="col-sm-2 offset-sm-1">
            <h4>Team Leadership</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Promotes a spirit of teamwork within the school community</span>
            <BarChart :selfscore="selfScores[40]*1" :superscore="superScores[40]*1" :teacherscore="teacherScores[40]*1" :leadershipscore="leadershipScores[40]*1" :compositescore="compositeScores[40]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Obtains resources and removes barriers to improve the effectiveness of teams</span>
            <BarChart :selfscore="selfScores[41]*1" :superscore="superScores[41]*1" :teacherscore="teacherScores[41]*1" :leadershipscore="leadershipScores[41]*1" :compositescore="compositeScores[41]*1" />
        </div>
    </div>
    <div class="row buffer">
        <div class="col-sm-4 offset-sm-3">
            <span class="barTitle">Establishes team goals that promote collaboration and individual effort</span>
            <BarChart :selfscore="selfScores[42]*1" :superscore="superScores[42]*1" :teacherscore="teacherScores[42]*1" :leadershipscore="leadershipScores[42]*1" :compositescore="compositeScores[42]*1" />
        </div>
        <hr />
    </div>

    <div class="row buffer printbreak">
        <div class="col-sm-2 offset-sm-1">
            <h4>Self-Confidence</h4>
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Demonstrates confidence to achieve ambitious goals</span>
            <BarChart :selfscore="selfScores[43]*1" :superscore="superScores[43]*1" :teacherscore="teacherScores[43]*1" :leadershipscore="leadershipScores[43]*1" :compositescore="compositeScores[43]*1" />
        </div>
        <div class="col-sm-4">
            <span class="barTitle">Shows conviction in decisions despite initial opposition</span>
            <BarChart :selfscore="selfScores[44]*1" :superscore="superScores[44]*1" :teacherscore="teacherScores[44]*1" :leadershipscore="leadershipScores[44]*1" :compositescore="compositeScores[44]*1" />
        </div>
        <hr />
    </div>
    <br />

    <!-- Strengths (Highest Rated Actions) -->
    <div class="row printbreak" id="stOps">
        <div class="col-sm-10 offset-sm-1">
            <div class="sectionhdr"><h3 class="nudge">Focus on Strengths and Development Opportunities</h3></div>
            <br />
            <h4 class="nudge">Strengths - Highest Rated Turnaround Leader Actions</h4>
            <table class="table table-striped">
                <colgroup>
                    <col style="width:10%">
                    <col style="width:50%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Leader Action Clusters</th>
                        <th scope="col">Your 5 Highest Rated Actions</th>
                        <th scope="col" class="ctr">Supervisor</th>
                        <th scope="col" class="ctr">Teachers/Staff</th>
                        <th scope="col" class="ctr">Leadership Team</th>
                        <th scope="col" class="ctr">Self</th>
                        <th scope="col" class="ctr">Composite</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="strength in strengths" :key="strength.num">
                        <td>{{ strength.label }}</td>
                        <td>{{ strength.num }}. {{ qText[strength.num-1] }}</td>
                        <td class="ctr">{{ superScores[strength.num-1] > 0 ? superScores[strength.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ teacherScores[strength.num-1] > 0 ? teacherScores[strength.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ leadershipScores[strength.num-1] > 0 ? leadershipScores[strength.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ selfScores[strength.num-1] > 0 ? selfScores[strength.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ compositeScores[strength.num-1] > 0 ? compositeScores[strength.num-1] : "N/A" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />

    <!-- Opportunities (Lowest Rated Actions) -->
    <div class="row">
        <div class="col-sm-10 offset-sm-1">
            <h4 class="nudge">Development Opportunities - Lowest Rated Turnaround Leader Actions</h4>
            <table class="table table-striped">
                <colgroup>
                    <col style="width:10%">
                    <col style="width:50%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                    <col style="width:8%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Leader Action Clusters</th>
                        <th scope="col">Your 5 Lowest Rated Actions</th>
                        <th scope="col" class="ctr">Supervisor</th>
                        <th scope="col" class="ctr">Teachers/Staff</th>
                        <th scope="col" class="ctr">Leadership Team</th>
                        <th scope="col" class="ctr">Self</th>
                        <th scope="col" class="ctr">Composite</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="opp in opportunities" :key="opp.num">
                        <td>{{ opp.label }}</td>
                        <td>{{ opp.num }}. {{ qText[opp.num-1] }}</td>
                        <td class="ctr">{{ superScores[opp.num-1] > 0 ? superScores[opp.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ teacherScores[opp.num-1] > 0 ? teacherScores[opp.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ leadershipScores[opp.num-1] > 0 ? leadershipScores[opp.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ selfScores[opp.num-1] > 0 ? selfScores[opp.num-1] : "N/A" }}</td>
                        <td class="ctr">{{ compositeScores[opp.num-1] > 0 ? compositeScores[opp.num-1] : "N/A" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />
    <br />

    <!-- Unexpected Strengths -->
    <div class="row" id="unexpect">
        <div class="col-sm-10 offset-sm-1">
            <div class="sectionhdr"><h3 class="nudge">Unexpected Strengths and Blind Spots</h3></div>
            <br />
            <h4 class="nudge">Unexpected Strengths</h4>
            <table class="table table-striped">
                <colgroup>
                    <col style="width:10%">
                    <col style="width:70%">
                    <col style="width:10%">
                    <col style="width:10%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Leader Action Clusters</th>
                        <th scope="col">Actions for which your Overall Score is 6.5 or higher and at least 1.5 points higher than your Self Score</th>
                        <th scope="col" class="ctr">Self</th>
                        <th scope="col" class="ctr">Overall Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="unexStrength in unexStrengths" v-bind:key="unexStrength.num">
                        <td>{{ unexStrength.label }}</td>
                        <td>
                            {{ unexStrength.num }}. {{ unexStrength.text }}
                        </td>
                        <td class="ctr">{{ unexStrength.selfScore }}</td>
                        <td class="ctr">{{ unexStrength.compositeScore }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />

    <!-- Blind Spots -->
    <div class="row">
        <div class="col-sm-10 offset-sm-1">
            <h4 class="nudge">Blind Spots</h4>
            <table class="table table-striped">
                <colgroup>
                    <col style="width:10%">
                    <col style="width:70%">
                    <col style="width:10%">
                    <col style="width:10%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Leader Action Clusters</th>
                        <th scope="col">Actions for which your Overall Score is at least 3 points lower than your Self Score</th>
                        <th scope="col" class="ctr">Self</th>
                        <th scope="col" class="ctr">Overall Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="blindSpot in blindSpots" v-bind:key="blindSpot.num">
                        <td>{{ blindSpot.label }}</td>
                        <td>
                            {{ blindSpot.num }}. {{ blindSpot.text }}
                        </td>
                        <td class="ctr">{{ blindSpot.selfScore }}</td>
                        <td class="ctr">{{ blindSpot.compositeScore }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />
    <br />

    <!-- Narrative Responses -->
    <div class="row" id="narResp">
        <div class="col-sm-10 offset-sm-1">
            <div class="sectionhdr"><h3 class="nudge">Narrative Responses for Leadership Strengths and Development Opportunities</h3></div>
            <br />
            <h4 class="nudge">Leadership Strengths</h4>
            <p class="nudge embolden">1) List three critical strengths that you think this person brings to school leadership.</p>
            <p v-for="str in NarrativeStrengths" v-bind:key="str.id" class="indent"><span v-html="str.commentText.replace(/\n/g, '<br />')"></span></p>
        </div>
    </div>
    <hr />
    <br />

    <div class="row">
        <div class="col-sm-10 offset-sm-1">
            <h4 class="nudge">Development Opportunities</h4>
            <p class="nudge embolden">1) List three ways you think this person can improve their performance as a school leader.</p>
            <p v-for="opp in NarrativeOpportunities" v-bind:key="opp.id" class="indent"><span v-html="opp.commentText.replace(/\n/g, '<br />')"></span></p>
        </div>
    </div>
    <hr />
    <br />

    <div class="row">
        <div class="col-sm-10 offset-sm-1">
            <h4 class="nudge raise">Optional Narrative Responses</h4>
            <div v-for="clusterTitle in narrativeClusters" v-bind:key="clusterTitle" class="indent">
                <p><strong>{{ clusterTitle }}</strong></p>
                <div v-for="optRes in OptionalNarrativeResponses" v-bind:key="optRes.id" v-show="optRes.clusterText==clusterTitle">
                    <span v-html="optRes.commentText.replace(/\n/g, '<br />')"></span>
                </div>
                <hr class="raise" />
            </div>
        </div>
    </div>
    <br />
    <br />

    <!-- Appendices -->
    <div class="row printbreak" id="appendix1">
        <div class="col-sm-10 offset-sm-1">
            <h2 class="nudge">Appendix A.1</h2>
            <h3 class="nudge">School Turnaround Leader Competencies</h3>
            <br />
            <p class="indent"><strong>Achievement:</strong>&nbsp; The drive and actions to set challenging goals and reach a high standard of performance despite barriers.</p>
            <p class="indent"><strong>Monitoring & Directiveness:</strong>&nbsp; The ability to set clear expectations and to hold others accountable for performance.</p>
            <p class="indent"><strong>Planning Ahead:</strong>&nbsp; A bias towards planning in order to derive future benefits or to avoid problems. </p>
            <p class="indent"><strong>Impact and Influence:</strong>&nbsp; Acting with the purpose of affecting the perceptions, thinking, and actions of others.</p>
            <p class="indent"><strong>Team Leadership:</strong>&nbsp; Assuming authoritative leadership of a group for the benefit of the organization.</p>
            <p class="indent"><strong>Conceptual and Analytical Thinking:</strong>&nbsp; The ability to break things down in a logical way and to recognize cause and effect. The ability to see patterns and links among seemingly unrelated things.</p>
            <p class="indent"><strong>Self-Confidence:</strong>&nbsp; A personal belief in one's ability to accomplish tasks and the actions that reflect that belief.</p>
        </div>
    </div>
    <br />
    <br />

    <div class="row" id="appendix2">
        <div class="col-sm-10 offset-sm-1">
            <h2 class="nudge">Appendix A.2</h2>
            <h3 class="nudge">School Turnaround Leader Actions</h3>
            <br />
            <h4 class="nudge">1. <i>Focus on a Few Early Wins - Use Momentum to Complete the Turnaround</i></h4>
            <ul class="liststyle">
                <li><strong>Collect and analyze data:</strong> Successful turnaround leaders are focused, fearless data hounds. Initially, turnaround leaders personally analyze data about the organization's performance to identify high-priority problems that can be fixed quickly.</li>
                <li><strong>Make action plan based on data:</strong> Turnaround leaders make an action plan that includes annual goals and major steps, with enough detail that each group in the school community knows specifically what they need to do differently. This allows people to focus on changing what they do, rather than worrying about impending change. Plan should cover years 1 - 3, with more detail for year one.</li>
                <li><strong>Focus on a few early wins in year one:</strong> Successful turnaround leaders choose a few high-priority goals with visible payoffs and use early success to gain momentum. While these "wins" are limited in scope, they are high-priority, non-peripheral elements of organization performance, and they are bold in speed and magnitude of change. Early wins are critical for motivating staff and disempowering naysayers</li>
                <li><i><strong>Sustaining Action:</strong> After the turnaround is complete, focus on continuous improvement.</i></li>
            </ul>
            <br />
            <h4 class="nudge">2. <i>Lead a Turnaround Campaign</i></h4>
            <ul class="liststyle">
                <li><strong>Communicate a positive vision:</strong> Turnaround leaders start their turnaround campaign by communicating a clear picture of success and its benefits. This motivates others to contribute their discretionary effort.</li>
                <li><strong>Gain support of key influencers:</strong> Turnaround leaders gain support of trusted influencers among staff and community and then work through these people to influence others.</li>
                <li><strong>Silence critics with speedy success:</strong> Turnaround leaders use early wins not just for success in their own right, but to cast vocal naysayers as champions of failure. This reduces leader time spent on "politics" and increases time spent managing for results.</li>
                <li><strong>Help staff personally feel problems:</strong> Turnaround leaders use various tactics to help staff empathize with (or put themselves in the shoes of) those whom they serve. This helps staff feel the problems that the status quo is causing and feel motivated to change.</li>
                <li><i><strong>Sustaining Action:</strong> Continue using these influence strategies to achieve improvement goals every year.</i></li>
            </ul>
            <br />
            <h4 class="nudge">3. <i>Get the Right Staff, Right the Remainder</i></h4>
            <ul class="liststyle">
                <li><strong>Require all staff to change:</strong> When turnaround leaders implement an action plan, change is mandatory not optional, beginning with accountable team leaders in the organization. </li>
                <li><strong>Make necessary staff and leader replacements:</strong> Successful turnaround leaders typically do not replace all or most staff but often replace team leaders who fail to organize and drive change. After initial turnaround success, staff who do not make needed changes either leave or are removed by the leader.</li>
                <li><i><strong>Sustaining Action:</strong> Continue developing leadership skills of team leaders and teaching skills of all teachers.</i></li>
            </ul>
            <br />
            <h4 class="nudge">4. <i>Drive Decisions with Open-Air Data</i></h4>
            <ul class="liststyle">
                <li><strong>Measure and report progress frequently:</strong> Turnaround leaders set up systems to measure and report interim results often. This enables the rapid discard of failed tactics and increase of successful tactics essential for fast results.</li>
                <li><strong>Require decision makers to share data and solve problems:</strong> Turnaround leaders report key staff results visibly and often. All key staff, starting with team leaders, are required to share periodic results in open-air sessions, shifting discussions from excuse-making and blaming to problem solving.</li>
                <li><i><strong>Sustaining Action:</strong> Continue open-air sharing of interim results among team leaders and within teams.</i></li>
            </ul>
            <br />
            <h4 class="nudge">5. <i>Break Organization Norms</i></h4>
            <ul class="liststyle">
                <li><strong>Break organization norms:</strong> In a failing organization, existing practices contribute to failure. Successful turnaround leaders break rules and norms to achieve success. Deviating to achieve early wins shows that new action gets new results.</li>
                <li><i><strong>Sustaining Action:</strong> When problems are identified, allow new, creative solutions, even after turnaround.</i></li>
            </ul>
            <br />
            <h4 class="nudge">6. <i>Do What Works; Raise the Bar</i></h4>
            <ul class="liststyle">
                <li><strong>Focus on successful tactics, halt others:</strong> Successful turnaround leaders quickly discard tactics that do not work and spend more money and time on tactics that work. This pruning and growing process focuses limited resources where they will best improve results.</li>
                <li><strong>Resist touting progress as ultimate success:</strong> Turnaround leaders are not satisfied with partial success. They report progress but keep the organization focused on high goals. When a goal is met, they are likely to raise the bar. Merely better is not enough.</li>
                <li><i><strong>Sustaining Action:</strong> Continue to improve or eliminate practices not contributing to success.</i></li>
            </ul>
        </div>
    </div>
    <br />

    <div class="row" id="appendix3">
        <div class="col-sm-8 offset-sm-1">
            <h2 class="nudge">Appendix A.3</h2>
            <h3 class="nudge">Turnaround Leader Action Clusters and Primary Competencies</h3>
            <br />
            <table class="table table-striped">
                <colgroup>
                    <col style="width:50%">
                    <col style="width:50%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Action Clusters</th>
                        <th scope="col">Primary Competencies</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Focus on a Few Early Wins:</strong>
                            <ul class="liststyle">
                                <li>Collect and analyze data</li>
                                <li>Make action plan based on data</li>
                                <li>Focus on a few early wins in year one</li>
                            </ul>
                        </td>
                        <td>
                            <br />
                            <ul>
                                <li>Conceptual and Analytical Thinking</li>
                                <li>Achievement</li>
                                <li>Planning Ahead</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Lead a Turnaround Campaign:</strong>
                            <ul class="liststyle">
                                <li>Communicate a positive vision</li>
                                <li>Gain support of key influencers</li>
                                <li>Silence critics with speedy success</li>
                                <li>Help staff personally feel problems</li>
                            </ul>
                        </td>
                        <td>
                            <br />
                            <ul>
                                <li>Impact and Influence</li>
                                <li>Team Leadership</li>
                                <li>Self-Confidence</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Get the Right Staff; Right the Remainder:</strong>
                            <ul class="liststyle">
                                <li>Require all staff to change</li>
                                <li>Make necessary staff and leader replacements</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Monitoring and Directiveness</li>
                                <li>Impact and Influence</li>
                                <li>Team Leadership</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Drive Decisions with Open-Air Data:</strong>
                            <ul class="liststyle">
                                <li>Measure and report progress frequently</li>
                                <li>Require decision makers to share data and solve problems</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Monitoring and Directiveness</li>
                                <li>Team Leadership</li>
                                <li>Conceptual and Analytical Thinking</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Break Organizational Norms:</strong>
                            <ul class="liststyle">
                                <li>Break organizational norms</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Achievement</li>
                                <li>Self-Confidence</li>
                                <li>Impact and Influence</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Do What Works; Raise the Bar:</strong>
                            <ul class="liststyle">
                                <li>Focus on successful tactics; halt others</li>
                                <li>Resist touting progress as ultimate success</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Achievement</li>
                                <li>Monitoring and Directiveness</li>
                                <li>Conceptual and Analytical Thinking</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />

    <div class="row" id="appendix4">
        <div class="col-sm-8 offset-sm-1">
            <h2 class="nudge">Appendix A.4</h2>
            <h3 class="nudge">Turnaround Leader 360 Participants</h3>
            <h5 class="nudge">Leader: {{ leaderFullName }} </h5>
            <h5 class="nudge">School: {{ schoolName }} </h5>
            <h5 class="nudge">Date: {{ todaysDate }} </h5>
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-sm-8 offset-sm-1">
            <table class="table">
                <colgroup>
                    <col style="width:40%">
                    <col style="width:10%">
                    <col style="width:25%">
                    <col style="width:25%">
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">Participants</th>
                        <th scope="col" class="ctr">% Complete</th>
                        <th scope="col" class="ctr">Last Emailed</th>
                        <th scope="col" class="ctr">Survey End</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="tblhdr">
                        <th>Leader</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="survey in leaderSurveys" :key="survey.Username">
                        <td>{{ survey.fullName }} &nbsp;&nbsp;&nbsp;&nbsp;<em>{{ survey.email }}</em></td>
                        <td class="ctr">{{ survey.progressPercent }}%</td>
                        <td class="ctr">{{ survey.lastEmailed }}</td>
                        <td class="ctr">{{ survey.surveyEnd }}</td>
                    </tr>
                    <tr v-if="leaderSurveys.length==0" class="nousers">
                        <td>There are no leaders assigned.</td>
                    </tr>
                    <tr class="tblhdr">
                        <th>Supervisor</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="survey in supervisorSurveys" :key="survey.username">
                        <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                        <td class="ctr">{{ survey.progressPercent }}%</td>
                        <td class="ctr">{{ survey.lastEmailed }}</td>
                        <td class="ctr">{{ survey.surveyEnd }}</td>
                    </tr>
                    <tr v-if="supervisorSurveys.length==0" class="nousers">
                        <td>There are no supervisors assigned.</td>
                    </tr>
                    <tr class="tblhdr">
                        <th>Teachers and Staff</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="survey in teacherSurveys" :key="survey.username">
                        <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                        <td class="ctr">{{ survey.progressPercent }}%</td>
                        <td class="ctr">{{ survey.lastEmailed }}</td>
                        <td class="ctr">{{ survey.surveyEnd }}</td>
                    </tr>
                    <tr v-if="teacherSurveys.length==0" class="nousers">
                        <td>There are no teachers assigned.</td>
                    </tr>
                    <tr class="tblhdr">
                        <th>Leadership Team Members</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr v-for="survey in leadershipSurveys" :key="survey.username">
                        <td>{{ survey.fullName }} &nbsp;<em>{{ survey.email }}</em></td>
                        <td class="ctr">{{ survey.progressPercent }}%</td>
                        <td class="ctr">{{ survey.lastEmailed }}</td>
                        <td class="ctr">{{ survey.surveyEnd }}</td>
                    </tr>
                    <tr v-if="leadershipSurveys.length==0" class="nousers">
                        <td>There are no leadership team members assigned.</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <div class="d-none d-sm-block">
                <button class="btn btn-large printbtn noprint" @click="PrintReport" v-show="!isLoading">Print</button>
            </div>
        </div>
    </div>
    <br />
    <br />
    <br />
</template>

<script>
    import axios from 'axios';
    import BarChart from './BarChart'
    import ColumnChart from './ColumnChart'
    import CompositeChart from './CompositeChart'
    import RingLoader from 'vue-spinner/src/RingLoader.vue'

    export default {
        name: "Results",

        data() {
            return {
                title: "Results",
                qText: [],
                clusterText: [],
                superScores: [],
                selfScores: [],
                teacherScores: [],
                leadershipScores: [],
                compositeScores: [],
                unexStrengths: [],
                blindSpots: [],
                unexStrNums: [],
                blindSpotNums: [],
                narrativeResponses: [],
                narrativeClusters: [],
                leaderFullName: "",
                schoolName: "",
                todaysDate: "",
                strengths: [],
                opportunities: [],
                isLoading: true,
                color: '#66ccff'
            }
        },

        components: {
            BarChart,
            ColumnChart,
            CompositeChart,
            RingLoader
        },

        methods: {
            GetLeaderFullName() {
                axios.get('leader/getinfo', { params: { leaderName: this.$store.state.leaderAlias } })
                    .then((res) => {
                        this.leaderFullName = res.data[0];
                        this.schoolName = res.data[1];
                        this.todaysDate = res.data[2];
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            GetNarrativeResponses() {
                axios.get('results/getnarrative', { params: { leaderName: this.$store.state.leaderAlias } })
                    .then((res) => {
                        this.narrativeResponses = res.data;
                        var arrayLen = this.narrativeResponses.length;
                        for (var i = 0; i < arrayLen; i++) {         // Build an array of unique cluster titles
                            if (this.narrativeResponses[i].clusterText != "") {
                                if (!this.narrativeClusters.includes(this.narrativeResponses[i].clusterText)) {
                                    this.narrativeClusters.push(this.narrativeResponses[i].clusterText);
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            GetScores() {
                axios.get('results/getscores', { params: { leaderName: this.$store.state.leaderAlias } })
                    .then((res) => {
                        for (var i = 0; i < 45; i++) {
                            this.qText[i] = res.data[i].questionText;
                            this.selfScores[i] = res.data[i].selfScore;
                            this.superScores[i] = res.data[i].superScore;
                            this.teacherScores[i] = res.data[i].teacherScore;
                            this.leadershipScores[i] = res.data[i].leadershipScore;
                            this.compositeScores[i] = res.data[i].compositeScore;
                            this.clusterText[i] = res.data[i].clusterText;
                            if (res.data[i].specialCategory == "UnexStrength") {
                                this.unexStrNums.push(res.data[i].questionNum);
                            }
                            if (res.data[i].specialCategory == "BlindSpot") {
                                this.blindSpotNums.push(res.data[i].questionNum);
                            }
                        }                               

                        // Build unexpected strengths and blind spots arrays and sort by composite score
                        this.unexStrNums.forEach(strNum => {
                            var unexStrength = {
                                num: strNum,
                                label: this.clusterText[strNum - 1],
                                text: this.qText[strNum - 1],
                                selfScore: this.selfScores[strNum - 1],
                                compositeScore: this.compositeScores[strNum - 1]
                            };

                            this.unexStrengths.push(unexStrength);
                        });

                        this.blindSpotNums.forEach(blindNum => {
                            var blindSpot = {
                                num: blindNum,
                                label: this.clusterText[blindNum - 1],
                                text: this.qText[blindNum - 1],
                                selfScore: this.selfScores[blindNum - 1],
                                compositeScore: this.compositeScores[blindNum - 1]
                            };

                            this.blindSpots.push(blindSpot);
                        });

                        this.unexStrengths = this.unexStrengths.sort((a, b) => {
                            let fa = a.compositeScore, fb = b.compositeScore;
                            if (fa < fb) {
                                return -1
                            }
                            if (fa > fb) {
                                return 1
                            }
                            return 0
                        });

                        this.blindSpots = this.blindSpots.sort((a, b) => {
                            let fa = a.compositeScore, fb = b.compositeScore;
                            if (fa < fb) {
                                return -1
                            }
                            if (fa > fb) {
                                return 1
                            }
                            return 0
                        });

                        // Populate strengths and opportunities arrays
                        for (i = 0; i < 5; i++) {
                            var strNum = res.data[45 + i].questionNum;
                            var strLbl = res.data[45 + i].clusterText;
                            var oppNum = res.data[50 + i].questionNum;
                            var oppLbl = res.data[50 + i].clusterText;

                            var strength = {
                                num: strNum,
                                label: strLbl
                            };
                            var opportunity = {
                                num: oppNum,
                                label: oppLbl
                            }

                            this.strengths.push(strength);
                            this.opportunities.push(opportunity);
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            GetFile(fileName) {
                this.showMessage = false;
                axios({
                    url: 'api/content/getfile',
                    method: 'GET',
                    params: { "fileName": fileName },
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            PrintReport() {
                window.print();
            },

            CompileScores(...scores) {
                var scoreCount = 0;
                var scoreTotal = 0;

                for (let i = 0; i < scores.length; i++) {
                    if (scores[i] > 0) {
                        scoreCount++;   // Questions with an N/A response (value of zero) should not be factored into averages.
                        scoreTotal += scores[i];
                    }
                }

                if (scoreCount === 0)
                    return 0;

                return Math.round(scoreTotal / scoreCount * 100) / 100;
            }
        },

        computed: {
            // Ratings on Leader Action Cluster Scores
            A1E1F1self() {
                return this.CompileScores(this.selfScores[30], this.selfScores[31]); // selfScores[30] is Question 31 due to arrays starting with 0th element
            },
            A1E1F1super() {
                return this.CompileScores(this.superScores[30], this.superScores[31]);
            },
            A1E1F1teachers() {
                return this.CompileScores(this.teacherScores[30], this.teacherScores[31]);
            },
            A1E1F1leadership() {
                return this.CompileScores(this.leadershipScores[30], this.leadershipScores[31]);
            },
            A1E1F1composite() {
                return this.CompileScores(this.A1E1F1super, this.A1E1F1teachers, this.A1E1F1leadership);
            },
            A2self() {
                return this.CompileScores(this.selfScores[34], this.selfScores[35]);
            },
            A2super() {
                return this.CompileScores(this.superScores[34], this.superScores[35]);
            },
            A2teachers() {
                return this.CompileScores(this.teacherScores[34], this.teacherScores[35]);
            },
            A2leadership() {
                return this.CompileScores(this.leadershipScores[34], this.leadershipScores[35]);
            },
            A2composite() {
                return this.CompileScores(this.A2super, this.A2teachers, this.A2leadership);
            },
            A3D3F3self() {
                return this.CompileScores(this.selfScores[32], this.selfScores[33]);
            },
            A3D3F3super() {
                return this.CompileScores(this.superScores[32], this.superScores[33]);
            },
            A3D3F3teachers() {
                return this.CompileScores(this.teacherScores[32], this.teacherScores[33]);
            },
            A3D3F3leadership() {
                return this.CompileScores(this.leadershipScores[32], this.leadershipScores[33]);
            },
            A3D3F3composite() {
                return this.CompileScores(this.A3D3F3super, this.A3D3F3teachers, this.A3D3F3leadership);
            },
            A4self() {
                return this.CompileScores(this.selfScores[0], this.selfScores[1], this.selfScores[2], this.selfScores[3], this.selfScores[4],
                       this.A1E1F1self, this.A2self, this.A3D3F3self);
            },
            A4super() {
                return this.CompileScores(this.superScores[0], this.superScores[1], this.superScores[2], this.superScores[3], this.superScores[4],
                       this.A1E1F1super, this.A2super, this.A3D3F3super);
            },
            A4teachers() {
                return this.CompileScores(this.teacherScores[0], this.teacherScores[1], this.teacherScores[2], this.teacherScores[3], this.teacherScores[4],
                       this.A1E1F1teachers, this.A2teachers, this.A3D3F3teachers);
            },
            A4leadership() {
                return this.CompileScores(this.leadershipScores[0], this.leadershipScores[1], this.leadershipScores[2], this.leadershipScores[3], this.leadershipScores[4],
                       this.A1E1F1leadership, this.A2leadership, this.A3D3F3leadership);
            },
            A4composite() {
                return this.CompileScores(this.A4super, this.A4teachers, this.A4leadership);
            },
            B1C2E3self() {
                return this.CompileScores(this.selfScores[38], this.selfScores[39]);
            },
            B1C2E3super() {
                return this.CompileScores(this.superScores[38], this.superScores[39]);
            },
            B1C2E3teachers() {
                return this.CompileScores(this.teacherScores[38], this.teacherScores[39]);
            },
            B1C2E3leadership() {
                return this.CompileScores(this.leadershipScores[38], this.leadershipScores[39]);
            },
            B1C2E3composite() {
                return this.CompileScores(this.B1C2E3super, this.B1C2E3teachers, this.B1C2E3leadership);
            },
            B2C3D2self() {
                return this.CompileScores(this.selfScores[39], this.selfScores[40], this.selfScores[42]);
            },
            B2C3D2super() {
                return this.CompileScores(this.superScores[39], this.superScores[40], this.superScores[42]);
            },
            B2C3D2teachers() {
                return this.CompileScores(this.teacherScores[39], this.teacherScores[40], this.teacherScores[42]);
            },
            B2C3D2leadership() {
                return this.CompileScores(this.leadershipScores[39], this.leadershipScores[40], this.leadershipScores[42]);
            },
            B2C3D2composite() {
                return this.CompileScores(this.B2C3D2super, this.B2C3D2teachers, this.B2C3D2leadership);
            },
            B3E2self() {
                return this.CompileScores(this.selfScores[43], this.selfScores[44]);
            },
            B3E2super() {
                return this.CompileScores(this.superScores[43], this.superScores[44]);
            },
            B3E2teachers() {
                return this.CompileScores(this.teacherScores[43], this.teacherScores[44]);
            },
            B3E2leadership() {
                return this.CompileScores(this.leadershipScores[43], this.leadershipScores[44]);
            },
            B3E2composite() {
                return this.CompileScores(this.B3E2super, this.B3E2teachers, this.B3E2leadership);
            },
            B4self() {
                return this.CompileScores(this.selfScores[5], this.selfScores[6], this.selfScores[7], this.selfScores[8], this.selfScores[9], this.selfScores[10],
                       this.B1C2E3self, this.B2C3D2self, this.B3E2self);
            },
            B4super() {
                return this.CompileScores(this.superScores[5], this.superScores[6], this.superScores[7], this.superScores[8], this.superScores[9], this.superScores[10],
                       this.B1C2E3super, this.B2C3D2super, this.B3E2super);
            },
            B4teachers() {
                return this.CompileScores(this.teacherScores[5], this.teacherScores[6], this.teacherScores[7], this.teacherScores[8], this.teacherScores[9], this.teacherScores[10],
                       this.B1C2E3teachers, this.B2C3D2teachers, this.B3E2teachers);
            },
            B4leadership() {
                return this.CompileScores(this.leadershipScores[5], this.leadershipScores[6], this.leadershipScores[7], this.leadershipScores[8], this.leadershipScores[9],
                       this.leadershipScores[10], this.B1C2E3leadership, this.B2C3D2leadership, this.B3E2leadership);
            },
            B4composite() {
                return this.CompileScores(this.B4super, this.B4teachers, this.B4leadership);
            },
            C1D1F2self() {
                return this.CompileScores(this.selfScores[36], this.selfScores[37]);
            },
            C1D1F2super() {
                return this.CompileScores(this.superScores[36], this.superScores[37]);
            },
            C1D1F2teachers() {
                return this.CompileScores(this.teacherScores[36], this.teacherScores[37]);
            },
            C1D1F2leadership() {
                return this.CompileScores(this.leadershipScores[36], this.leadershipScores[37]);
            },
            C1D1F2composite() {
                return this.CompileScores(this.C1D1F2super, this.C1D1F2teachers, this.C1D1F2leadership);
            },
            C4self() {
                return this.CompileScores(this.selfScores[11], this.selfScores[12], this.selfScores[13], this.selfScores[14], this.selfScores[15], this.selfScores[16],
                       this.C1D1F2self, this.B1C2E3self, this.B2C3D2self);
            },
            C4super() {
                return this.CompileScores(this.superScores[11], this.superScores[12], this.superScores[13], this.superScores[14], this.superScores[15], this.superScores[16],
                       this.C1D1F2super, this.B1C2E3super, this.B2C3D2super);
            },
            C4teachers() {
                return this.CompileScores(this.teacherScores[11], this.teacherScores[12], this.teacherScores[13], this.teacherScores[14], this.teacherScores[15],
                       this.teacherScores[16], this.C1D1F2teachers, this.B1C2E3teachers, this.B2C3D2teachers);
            },
            C4leadership() {
                return this.CompileScores(this.leadershipScores[11], this.leadershipScores[12], this.leadershipScores[13], this.leadershipScores[14], this.leadershipScores[15],
                       this.leadershipScores[16], this.C1D1F2leadership, this.B1C2E3leadership, this.B2C3D2leadership);
            },
            C4composite() {
                return this.CompileScores(this.C4super, this.C4teachers, this.C4leadership);
            },
            D4self() {
                return this.CompileScores(this.selfScores[17], this.selfScores[18], this.selfScores[19], this.selfScores[20], this.selfScores[21], this.selfScores[22],
                       this.C1D1F2self, this.B2C3D2self, this.A3D3F3self);
            },
            D4super() {
                return this.CompileScores(this.superScores[17], this.superScores[18], this.superScores[19], this.superScores[20], this.superScores[21], this.superScores[22],
                       this.C1D1F2super, this.B2C3D2super, this.A3D3F3super);
            },
            D4teachers() {
                return this.CompileScores(this.teacherScores[17], this.teacherScores[18], this.teacherScores[19], this.teacherScores[20], this.teacherScores[21],
                       this.teacherScores[22], this.C1D1F2teachers, this.B2C3D2teachers, this.A3D3F3teachers);
            },
            D4leadership() {
                return this.CompileScores(this.leadershipScores[17], this.leadershipScores[18], this.leadershipScores[19], this.leadershipScores[20], this.leadershipScores[21],
                       this.leadershipScores[22], this.C1D1F2leadership, this.B2C3D2leadership, this.A3D3F3leadership);
            },
            D4composite() {
                return this.CompileScores(this.D4super, this.D4teachers, this.D4leadership);
            },
            E4self() {
                return this.CompileScores(this.selfScores[23], this.selfScores[24], this.selfScores[25], this.A1E1F1self, this.B3E2self, this.B1C2E3self);
            },
            E4super() {
                return this.CompileScores(this.superScores[23], this.superScores[24], this.superScores[25], this.A1E1F1super, this.B3E2super, this.B1C2E3super);
            },
            E4teachers() {
                return this.CompileScores(this.teacherScores[23], this.teacherScores[24], this.teacherScores[25], this.A1E1F1teachers, this.B3E2teachers, this.B1C2E3teachers);
            },
            E4leadership() {
                return this.CompileScores(this.leadershipScores[23], this.leadershipScores[24], this.leadershipScores[25], this.A1E1F1leadership, this.B3E2leadership, this.B1C2E3leadership);
            },
            E4composite() {
                return this.CompileScores(this.E4super, this.E4teachers, this.E4leadership);
            },
            F4self() {
                return this.CompileScores(this.selfScores[26], this.selfScores[27], this.selfScores[28], this.selfScores[29], this.A1E1F1self, this.C1D1F2self, this.A3D3F3self);
            },
            F4super() {
                return this.CompileScores(this.superScores[26], this.superScores[27], this.superScores[28], this.superScores[29], this.A1E1F1super, this.C1D1F2super, this.A3D3F3super);
            },
            F4teachers() {
                return this.CompileScores(this.teacherScores[26], this.teacherScores[27], this.teacherScores[28], this.teacherScores[29], this.A1E1F1teachers, this.C1D1F2teachers, this.A3D3F3teachers);
            },
            F4leadership() {
                return this.CompileScores(this.leadershipScores[26], this.leadershipScores[27], this.leadershipScores[28], this.leadershipScores[29],
                       this.A1E1F1leadership, this.C1D1F2leadership, this.A3D3F3leadership);
            },
            F4composite() {
                return this.CompileScores(this.F4super, this.F4teachers, this.F4leadership);
            },

            // Primary Turnaround Leader Competencies
            Achvmt() {
                return this.CompileScores(this.A1E1F1super, this.A1E1F1teachers, this.A1E1F1leadership);
            },
            CAT() {
                return this.CompileScores(this.A3D3F3super, this.A3D3F3teachers, this.A3D3F3leadership);
            },
            PlanAhd() {
                return this.CompileScores(this.A2super, this.A2teachers, this.A2leadership);
            },
            MaD() {
                return this.CompileScores(this.C1D1F2super, this.C1D1F2teachers, this.C1D1F2leadership);
            },
            IaI() {
                return this.CompileScores(this.B1C2E3super, this.B1C2E3teachers, this.B1C2E3leadership);
            },
            TmLdrshp() {
                return this.CompileScores(this.B2C3D2super, this.B2C3D2teachers, this.B2C3D2leadership);
            },
            SelfCon() {
                return this.CompileScores(this.B3E2super, this.B3E2teachers, this.B3E2leadership);
            },

            // Narrative Responses
            NarrativeStrengths() {
                return this.narrativeResponses.filter(res => res.commentGroup === 1);
            },
            NarrativeOpportunities() {
                return this.narrativeResponses.filter(res => res.commentGroup === 2);
            },
            OptionalNarrativeResponses() {
                return this.narrativeResponses.filter(res => res.page < 10);
            },

            // Participant Info
            leaderSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Leader');
            },
            supervisorSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Supervisor');
            },
            teacherSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Teacher');
            },
            leadershipSurveys() {
                return this.$store.state.surveys.filter(survey => survey.role === 'Leadership Team');
            }
        },

        mounted() {
            document.title = "Turnaround 360";

            setTimeout(() => {
                this.isLoading = false;
            }, 5000)

            /*
                if (navigator.userAgent.match(/firefox|fxios/i)) {
                    this.browserName = "firefox";
                }
                if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
                    this.browserName = "chrome";
                }   Also could check for (/safari/i) (/opr\//i) (/edg/i)
            */

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                this.GetLeaderFullName();
                this.GetScores();
                this.GetNarrativeResponses();
            }
        }
    }
</script>

<style>
    .sectionhdr {
        background-color: #dfbf9f;
        padding: 8px 0 4px 4px;
    }

    .charthdr {
        background-color: #009999;
        padding: 8px 0 4px 4px;
        color: #ffffff;
    }

    .nudge {
        margin-left: 5px;
    }

    .ctr {
        text-align: center;
    }

    .embolden {
        font-weight: bold;
    }

    .indent {
        margin-left: 40px;
    }

    .liststyle {
        list-style-type: disc;
    }

    .raise {
        margin-bottom: 12px;
    }

    .buffer {
        margin-left: 60px;
    }

    .titlebuffer {
        margin-left: 60px;
    }

    .barTitle {
        font-weight: bold;
        font-size: 13px;
        margin: 0;
    }

    .clickable {
        cursor: pointer;
        color: #003399;
    }

    .tblhdr {
        background-color: #003399;
        color: #ffffff;
    }

    .rmvline td {
      border-bottom: none;
    }

    .nousers {
        text-align: center;
        margin: 8px 0;
    }

    .printbtn {
        float: right;
        background-color: #3771ab;
        color: #fcfcfc;
        margin-right: 125px;
    }

    @media print {
        @page {
            size: landscape
        }

        .printbreak {
            page-break-before: always;
            break-before: always;
        }

        .noprint {
            display: none;
        }
    }
</style>
