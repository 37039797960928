<template>
    <div id="wrapper">
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Logout",

        methods: {
            Logout() {
                axios.post('account/logout')
                    .then(() => {
                        this.$store.state.loggedName = "";
                        this.$store.state.leaderName = "";
                        this.$store.state.purchaserFullName = "";
                        this.$store.state.leaderFullName = "";
                        this.$store.state.leaderAlias = "";
                        this.$store.state.purchaserAlias = "";
                        this.$store.state.viewSession = "";
                        this.$store.state.searchUser = "";
                        this.$store.state.userRole = "";
                        this.$store.state.surveys = [];
                        this.$router.push('/login');
                    })
            }
        },
        
        mounted() {
            this.Logout();
        }
    }

</script>

<style scoped>
    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>