<template>
    <div class="row subhdr">
        <div class="col-10 offset-1">
            <h3 class="vpush"><strong>Learn more about the purpose of the Turnaround Leader 360 Feedback Assessment</strong></h3>
            <p class="textstyle">
                The survey report provides turnaround school leaders with insights into their leader actions and competencies from colleagues they interact with in
                a variety of professional settings, and the accompanying Facilitator's Guide and Participant Workbook suggest ways to use that information to craft
                personalized development goals.
            </p>
            <p class="textstyle">
                Download an abbreviated sample version of the <span class="clickable underline" @click="GetFile('sample_360FeedbackReport.pdf')">Turnaround Leader 360 Feedback Assessment report</span>.
            </p>
        </div>
    </div>

    <div class="row subhdr">
        <div class="col-11 col-lg-5 offset-1 mt-2 mb-2 mb-sm-0">
            <video controls poster="../assets/T-360_overview_First_Frame.png" class="video2_" aria-label="Turnaround Leader 360 Feedback Assessment Overview">
                <source src="../assets/T-360_Overview.mp4" type="video/mp4">
            </video>
        </div>
        <div class="col-10 col-lg-4 offset-1 offset-lg-0 ms-lg-2 mb-lg-2 mt-sm-2 purchasebox">
            <h4>
                <strong>
                    <a href="https://region-13-products.myshopify.com/products/turnaround-leader-360-feedback-assessment?variant=17441291205" target="_blank" class="clickable" aria-label="Purchase the Turnaround Leader 360 Feedback Assessment">
                        Purchase the Turnaround Leader 360 Feedback Assessment at the Region 13 Product Store
                    </a>
                </strong>
            </h4>
            <br />
            <a href="https://region-13-products.myshopify.com/products/turnaround-leader-360-feedback-assessment?variant=17441291205" target="_blank">
                <img src="../assets/turnaround_360_online_550x825.jpg" alt="" class="img360">
            </a>
        </div>
    </div>

    <div class="row subhdr">
        <div class="col-sm-6 offset-sm-3 offset-lg-0">
            <p class="ctr">
                <span class="clickable" @click="GetFile('T360_overview_transcript.pdf')">Download Overview Transcript</span>
            </p>
        </div>
        <br />
    </div>
    <br />

    <div class="row midsect">
        <div class="col-10 offset-1">
            <h3 class="vpush"><strong>Learn more about the features of the Turnaround Leader 360 Feedback Assessment</strong></h3>
            <ul>
                <li>Anonymous, online survey</li>
                <li>Based on a Likert-type scale</li>
                <li>Takes approximately 20-30 minutes to complete</li>
                <li>Generates a summary report that leaders and their coaches can use to understand their leadership strengths and areas for development</li>
            </ul>
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-10 offset-2 offset-lg-1 col-lg-5">
            <video controls poster="../assets/T-360_leader_First_Frame.png" class="video2" aria-label="Turnaround Leader 360 Leader Screencast">
                <source src="../assets/T-360_Leader.mp4" type="video/mp4">
            </video>
        </div>
        <div class="col-10 offset-2 offset-lg-0 col-lg-5">
            <video controls poster="../assets/T-360_responder_First_Frame.png" class="video3" aria-label="Turnaround Leader 360 Responder Screencast">
                >
                <source src="../assets/T-360_Responder.mp4" type="video/mp4">
            </video>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4 offset-lg-1">
            <p class="ctr">
                <span class="clickable" @click="GetFile('T360_leader_transcript.pdf')">Download Leader Transcript</span>
            </p>
        </div>
        <div class="col-12 col-lg-4 offset-lg-1">
            <p class="ctr">
                <span class="clickable" @click="GetFile('T360_responder_transcript.pdf')">Download Responder Transcript</span>
            </p>
        </div>
    </div>

    <div class="row midsect2">
        <div class="col-10 offset-1">
            <p>
                Although the T360 is primarily focused on Turnaround Leadership and School Improvement, the assessment is fully aligned with both the
                <a href="https://esc13.net/services/t-pess" target="blank">Texas Principal Evaluation and Support System (T-PESS)</a> and
                <a href="https://esc13.net/school-performance/esf" target="blank">The Effective Schools Framework (ESF)</a>.
                Download this <span class="clickable pdflink" @click="GetFile('T360_Alignment_TPESS_ESF.pdf')">PDF</span>
                for complete details on how the Turnaround Leader Competencies align with T-PESS Indicators as well as ESF Essential Actions.
            </p>
            <h3 style="margin-top:32px;"><strong>Turnaround Leadership Resources</strong></h3>
            <p><span class="clickable pdflink underline" @click="GetFile('360_Assessment_Guidance.pdf')">Turnaround Leader 360 Feedback Assessment Guidance</span></p>
            <p><a href="http://www4.esc13.net/" target="blank">Education Service Center Region 13</a></p>
            <p><a href="http://www.centerii.org/survey/downloads/Turnarounds-Color.pdf" target="blank">School Turnarounds</a></p>
            <p><a href="http://www.publicimpact.com/publications/Turnaround_Leader_Selection_Toolkit.pdf" target="blank">Turnaround Leaders</a></p>
            <p><a href="http://educationnext.org/the-big-uturn/" target="blank">Turnaround Leader Action Clusters</a></p>
            <!-- <p><a href="http://www.tcdss.net/" target="blank">The Texas Center for District and School Support</a></p> -->
            <!-- <p><a href="http://schoolturnarounds.org/" target="blank">Public Impact School Turnarounds</a></p> -->
            <br />
            <h3><strong>Contact Us</strong></h3>
            <p>
                If you have technical questions or questions about the process or content of the assessment, please contact us at:
                <br />
                <a href="mailto:TurnaroundLeader360-support@esc13.txed.net">TurnaroundLeader360-support@esc13.txed.net</a>
            </p>
            <br />
        </div>
    </div>
    <br />
    <br />
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Home',

        methods: {
            GetFile(fileName) {
                this.showMessage = false;
                axios({
                    url: 'api/content/getfile',
                    method: 'GET',
                    params: { "fileName": fileName },
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                });
            }
        },

        mounted() {
            document.title = "Turnaround 360";
        }
    }
</script>

<style scoped>
    .ctr {
        text-align: center;
    }

    .underline {
        text-decoration: underline;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding-top: 15px;
    }

    .midsect {
        background-color: #cce0ea;
        padding-top: 12px;
    }

    .midsect2 {
        background-color: #e1e9ed;
        padding-top: 18px;
        font-size: 1.1em;
    }

    .textstyle {
        font-size: 1.1em;
    }

    .vpush {
        margin-bottom: 12px;
    }

    .clickable {
        cursor: pointer;
        color: #003399;
    }

    .video1 {
        width: 50%;
        height: auto;
    }

    .video2_ { 
        width: 88%;
        height: auto;
    }

    .video2 {
        width: 88%;
        height: auto;
    }

    .video3 {
        width: 88%;
        height: auto;
    }

    .purchasebox {
        background-color: #cce0ea;
        text-align: center;
        padding-top: 12px;
        border: 1px solid #191970;
    }

    .purchasebox a:link {
        text-decoration: none;
        color: #303120;
    }

    .purchasebox a:visited {
        text-decoration: none;
        color: #303120;
    }

    .purchasebox a:hover {
        text-decoration: none;
        color: #303120;
    }

    .purchasebox a:active {
        text-decoration: none;
        color: #303120;
    }

    .pdflink {
        color: #0d6efd;
    }

    .img360 {
        width: 50%;
        height: auto;
    }
</style>
