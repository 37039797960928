
<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    <br />
</template>

<script>
    export default {
        name: "CompositeChart",

        props: {
            achvmt: Number,
            cat: Number,
            planahd: Number,
            mad: Number,
            iai: Number,
            tmldrshp: Number,
            selfcon: Number,
            title: String
        },

        data() {
            return {
                series: [{
                    data: [this.achvmt, this.cat, this.planahd, this.mad, this.iai, this.tmldrshp, this.selfcon]
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '35%',
                            distributed: true,
                        }
                    },
                    legend: {
                        show: false
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            if(val > 0) { return val; }
                        },
                        style: {
                            fontSize: '12px'
                        }
                    },
                    xaxis: {
                        categories: ["Achievement", "Conceptual/Analytical Thinking", "Planning Ahead", "Monitoring & Directiveness",
                            "Impact & Influence", "Team Leadership", "Self-Confidence"],
                        labels: {
                            style: {
                                colors: ['#0d6efd', '#20c997', '#ffc107', '#dc3545', '#6f42c1', '#546E7A', '#006600'],
                                fontSize: '11px'
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 8,
                        tickAmount: 8,
                        decimalsInFloat: 0
                    },
                    title: {
                        text: this.title,
                        floating: true,
                        align: 'center',
                        style: {
                            color: '#444'
                        }
                    }
                }
            }
        },

        beforeUpdate() {
            this.series = [{
                data: [this.achvmt, this.cat, this.planahd, this.mad, this.iai, this.tmldrshp, this.selfcon]
            }];

            this.chartOptions = {
                ...this.chartOptions, ...{
                    title: {
                        text: this.title
                    },

                }
            }
        }
    }
</script>