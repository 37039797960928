<template>
    <div id="wrapper">
        <div class="row subhdr">
            <div class="col-sm-10 offset-sm-1">
                <br />
                <h4 class="hdrspace"><strong>Guidelines for Choosing Responders</strong></h4>
                <p>
                    Please choose people who you believe will be honest, objective, and balanced in describing you. These should be people who know you and your work well enough to be useful in their feedback. You should invite 10 to 16 individuals to participate in the survey with a goal of having a minimum of 10 respondents for the feedback report.
                </p>
                <p>
                    Use this link or the button at the bottom of the page to enter the response group designation and e-mail addresses for your responders.
                </p>
                <p>
                    In order to preserve anonymity, at least three individuals must respond to the survey so that feedback will be presented as a separate response group (i.e. &quot;Teachers and Staff&quot; or &quot;Leadership Team Members&quot;). Otherwise, their responses will be calculated in the &quot;Composite&quot; score.
                </p>
                <br />
            </div>
        </div>
        <br />

        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <div class="card">
                    <div class="card-header infohdr">
                        <strong>Suggested Categories of Responders</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item infotext">
                            <strong>Supervisor</strong>
                            <ul class="vnudge">
                                <li>You should select one individual who will provide feedack as a supervisor.</li>
                                <li>If you have a dual or dotted line reporting structure, you should select the person who is in the best position to provide honest, objective and balanced feedback.</li>
                                <li>In the report, the supervisor's responses are not compiled in a group but are shown separately for clarity; supervisor feedback is therefore <u>not</u> anonymous.</li>
                            </ul>
                        </li>
                        <li class="list-group-item">
                            <strong>Teachers and Staff (not members of Leadership Team)</strong>
                            <ul class="vnudge">
                                <li>Identify a minimum of at least seven (7) teachers or school staff members that are not considered members of the school leadership team.</li>
                                <li>If direct supervisory responsibilities are divided between you and another member(s) of the leadership team, you should prioritize your direct reports.</li>
                                <li>If you have fewer than seven (7) direct reports, add teachers and staff with whom you most directly interact.</li>
                            </ul>
                        </li>
                        <li class="list-group-item infotext">
                            <strong>Leadership Team Members (teachers and staff)</strong>
                            <ul class="vnudge">
                                <li>
                                    The survey report is based on the assumption that you have a senior leadership team that consists of selected members of the teaching faculty and staff;
                                    these individuals may or may not be direct reports, but they should work closely with you on school-wide initiatives and/or strategic planning.
                                </li>
                                <li>Identify 3 to 5 members of the leadership team.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <br />
            <div class="col-sm-1 offset-sm-1">
                <button class="btn actionbtn" @click="Navigate" aria-label="Next">Next</button>
            </div>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    export default {
        name: "Guidelines",

        methods: {
            Navigate() {
                this.$router.push('/invite');
            }
        },

        mounted() {
            document.title = "Turnaround 360";

            if (this.$store.state.loggedName == "")
                this.$router.push('/reset');
        }
    }
</script>

<style scoped>
    .hdrspace {
        margin: 0 0 12px 6px;
    }

    .vnudge {
        margin-top: 8px;
    }

    p, button {
        margin-left: 8px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .footer {
        background-color: #d1d1e0;
        padding: 18px;
        text-align: center;
    }

    .infohdr {
        background-color: #191970;
        width: 100%;
        color: #fcfcfc;
    }

    .infotext {
        background-color: #e1e9ed;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
        margin: 20px;
    }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>