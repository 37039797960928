<template>
  <nav-menu></nav-menu>
  <router-view />
  <span v-if="$route.name!='Results'">
      <my-footer></my-footer>
  </span>
</template>

<script>

import NavMenu from './components/NavMenu.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
      NavMenu,
      myFooter: Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
