<template>
    <div id="wrapper" class="container-fluid">
        <div class="subhdr">
            <h1 class="ctr">Account Administration</h1>
            <br />
            <div class="row">
                <div class="col-6 offset-3 col-lg-4 offset-lg-4">
                    <form method="post">
                        <div class="form-group">
                            <label for="searchUser" class="lbl"><strong>Enter a username or email address to find an account:</strong></label>
                            <input name="searchUser" type="text" v-model="searchUser" class="form-control" placeholder="Username or Email" aria-label="Username or Email"
                                   v-bind:class="[(searchUserFail && (searchMessage=='You must input a valid username or email address.' || searchMessage=='Could not find user.')) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <p class="ctr"><button type="submit" class="btn btn-lg actionbtn" @click.prevent="UserSearch">Search</button></p>
                    </form>
                    <p class="ctr vspace" v-show="searchMessage.length>0" v-bind:class="[(searchUserFail==true) ? 'warning' : 'notice']">{{ searchMessage }}</p>
                </div>
            </div>
        </div>

        <div class="row userlist" v-show="userData.length > 0">
            <div class="col-sm-11 offset-sm-1 table-responsive">
                <table v-bind:class="[(this.windowWidth <= 1300) ? 'smalltable' : '']">
                    <colgroup>
                        <col style="width:12%">
                        <col style="width:8%">
                        <col style="width:10%">
                        <col style="width:12%">
                        <col style="width:10%">
                        <col style="width:8%">
                        <col style="width:10%">
                        <col style="width:30%">
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Role</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Purchaser / Leader</th>
                            <th scope="col">Seats Left</th>
                            <th scope="col">Account Created</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in userData" :key="user.username">
                            <td>{{ user.fullName }}</td>
                            <td>{{ user.role }}</td>
                            <td v-if="user.role=='Purchaser' || user.role=='Leader'" class="userselect" @click="BecomeUser(user.username, user.role)">{{ user.username }}</td>
                            <td v-else>{{ user.username }}</td>
                            <td>{{ user.email }}</td>
                            <td v-if="user.role=='Leader'" class="userselect ctr" @click="BecomeUser(user.purchaser, 'Purchaser')">{{ user.purchaser }}</td>
                            <td v-else class="ctr">{{ user.leader }}</td>
                            <td v-if="user.role=='Purchaser'">{{ user.seatsRemaining }} of {{ user.seatsPurchased }}</td>
                            <td v-else></td>
                            <td>{{ user.dateCreated }}</td>
                            <td>
                                <button class="btn btn-sm actionbtn leftbtn" v-if="user.role=='Purchaser'"
                                        v-scroll-to="{el: '#seatBox', duration: 100}" @click="ManageSeats(user.username, user.fullName, user.seatsRemaining, user.seatsPurchased)">
                                    Manage Seats
                                </button>
                                <button class="btn btn-sm actionbtn leftbtn" v-if="user.role=='Leader'" v-scroll-to="{el: '#reportBox', duration: 100}" @click="ForceVerify(user.username, user.fullName)">Force Report</button>
                                <button class="btn btn-sm actionbtn leftbtn" v-if="user.role=='Supervisor' || user.role=='Teacher' || user.role=='Leadership Team'"
                                        v-scroll-to="{el: '#resendBox', duration: 100}" @click="EmailSendVerify(user.username, user.fullName)">
                                    Send Email
                                </button>
                                <button class="btn btn-sm actionbtn" v-scroll-to="{el: '#resetBox', duration: 100}" @click="PwdVerify(user.username, user.fullName)">Reset Password</button>
                                <button class="btn btn-sm actionbtn" v-scroll-to="{el: '#deleteBox', duration: 100}" @click="DeleteVerify(user.username, user.fullName)" style="margin-right:24px;">Delete User</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row" v-if="showEmailSend" id="resendBox">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Send a reminder email to {{ activeFullName }} and extend deadline?</p>
                <button class="btn btn-sm btn-yes verifybtn" @click="ResendInvite">Yes</button>
                <button class="btn btn-sm btn-no verifybtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <div class="row" v-if="showForce" id="reportBox">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Reset the password for {{ activeFullName }}?</p>
                <button class="btn btn-sm btn-yes verifybtn" @click="ForceReport">Yes</button>
                <button class="btn btn-sm btn-no verifybtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <div class="row" v-if="showReset" id="resetBox">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Reset the password for {{ activeFullName }}?</p>
                <button class="btn btn-sm btn-yes verifybtn" @click="ResetPwd">Yes</button>
                <button class="btn btn-sm btn-no verifybtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <div class="row" v-if="showDelete" id="deleteBox">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Are you sure you want to delete {{ activeFullName }} from the system?</p>
                <button class="btn btn-sm btn-yes verifybtn" @click="DeleteUser">Yes</button>
                <button class="btn btn-sm btn-no verifybtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <div class="row" v-if="showSeats" id="seatBox">
            <div class="col-sm-4 offset-sm-4 ctr verifybox">
                <p>Seats for {{ activeFullName }}'s current session:</p>
                <p>
                    {{ seatsRemaining }} of
                    <input name="totalSeats" type="number" min="0" v-model="newSeatCount" class="seatinput" aria-label="Update Seat Count" />
                    used
                </p>
                <button class="btn btn-sm btn-info createbtn" @click="VerifySession">Create New Session</button>
                <button class="btn btn-sm btn-yes seatbtn" @click="UpdateSeats">Update Seats</button>
                <button class="btn btn-sm btn-no seatbtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <div class="row" v-if="showNewSession">
            <div class="col-sm-6 col-md-6 col-lg-5 offset-sm-4 ctr verifybox">
                <p>Create NEW session for {{ activeFullName }}:</p>
                <p>
                    <input name="totalSeats" type="number" min="0" v-model="newSessionSeats" class="seatinput" aria-label="New Session Seat Count"
                           v-bind:class="[(manageFail==true) ? 'warnstyle' : 'normalstyle']" />
                    seats
                </p>
                <button class="btn btn-sm btn-yes seatbtn" @click="CreateSession">Add Session</button>
                <button class="btn btn-sm btn-no seatbtn" @click="ClearWarnings">Cancel</button>
            </div>
        </div>

        <p class="ctr vspace" v-bind:class="[(this.manageFail==true) ? 'warning' : 'notice']" v-if="manageMessage.length>0">{{ manageMessage }}</p>

        <div class="row newacct">
            <div class="col-sm-6 col-md-4 col-lg-4 offset-sm-3 offset-md-4 offset-lg-4">
                <form method="post">
                    <div class="form-group form-inline">
                        <label for="createEmail" class="lbl"><strong>Create a new purchaser account:</strong></label>
                        <input name="createEmail" type="text" v-model="createEmail" class="form-control" placeholder="Email" aria-label="Email Address"
                               v-bind:class="[(this.purchaserFail && this.createEmail=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="Seats" type="number" v-model="seats" class="form-control" placeholder="Seats" min="0" aria-label="Number of Seats"
                               v-bind:class="[(this.purchaserFail && this.seats=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="FirstName" type="text" v-model="firstname" class="form-control" placeholder="First Name" aria-label="First Name"
                               v-bind:class="[(this.purchaserFail && this.firstname=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="LastName" type="text" v-model="lastname" class="form-control" placeholder="Last Name" aria-label="Last Name"
                               v-bind:class="[(this.purchaserFail && this.lastname=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="School" type="text" v-model="school" class="form-control" placeholder="School" aria-label="School"
                               v-bind:class="[(this.purchaserFail && this.school=='') ? 'warnstyle' : 'normalstyle']" />
                        <input name="District" type="text" v-model="district" class="form-control" placeholder="District" aria-label="District"
                               v-bind:class="[(this.purchaserFail && this.district=='') ? 'warnstyle' : 'normalstyle']" />

                        <StateList v-model="selectedState" v-bind:class="[(this.purchaserFail && this.selectedState==='NS') ? 'warnstyle' : 'normalstyle']"></StateList>
                    </div>
                    <br />
                    <p class="ctr"><button type="submit" class="btn btn-lg actionbtn" @click.prevent="CreatePurchaser">Create</button></p>
                </form>
                <p class="ctr vspace" v-show="purchaserMessage.length>0" v-bind:class="[(purchaserFail==true) ? 'warning' : 'notice']">{{ purchaserMessage }}</p>
            </div>
        </div>
        <br />
        <div class="row">
            <p class="ctr"><button type="submit" class="btn btn-lg actionbtn" @click.prevent="Reinvite">Go to reinvite page</button></p>
        </div>
        <br />
        <br />
    </div>
    <br />
</template>

<script>
    import axios from 'axios';
    import StateList from './StateList.vue';

    export default {
        name: "Admin",
        data() {
            return {
                title: "Admin",
                searchUser: "",
                createEmail: "",
                seats: "",
                firstname: "",
                lastname: "",
                school: "",
                district: "",
                selectedState: "NS",
                searchMessage: "",
                purchaserMessage: "",
                searchUserFail: false,
                purchaserFail: false,
                userData: [],
                activeUsername: "",
                activeFullName: "",
                showReset: false,
                showDelete: false,
                showSeats: false,
                seatsRemaining: 0,
                seatsPurchased: 0,
                newSeatCount: 0,
                manageMessage: "",
                manageFail: false,
                newSessionSeats: 0,
                showNewSession: false,
                showEmailSend: false,
                showForce: false,
                windowWidth: 0
            }
        },

        components: {
            StateList
        },

        methods: {
            UserSearch() {
                // this.GetPwd(); // WARNING!: Uncomment this only in development version to transform Search input element into password descrambler
                this.ClearWarnings();
                if (this.searchUser != "") {
                    axios.get('admin/findusers', { params: { searchUser: this.searchUser }})
                        .then((res) => {
                            this.userData = res.data;
                            this.$store.state.searchUser = this.searchUser;
                    })
                    .catch((error) => {
                        this.searchMessage = error.response.data;
                        this.searchUserFail = true;
                    });
                } else {
                    this.searchMessage = "You must input a valid username or email address.";
                    this.searchUserFail = true;
                    this.$store.state.searchUser = "";
                    this.userData = "";
                }
            },

            GetPwd() {
                if (this.searchUser != "") {
                    axios.get('admin/unscramblepwd', { params: { scramble: this.searchUser } })
                        .then((res) => {
                            if (res.data != "Invalid Environment") {
                                this.searchUser = res.data;
                            }
                        });
                }
            },

            CreatePurchaser() {
                this.ClearWarnings();
                if (this.ValidateInput() === true) {
                    var postData = {
                        "Role": "Purchaser", "Email": this.createEmail, "Seats": this.seats, "FirstName": this.firstname,
                        "LastName": this.lastname, "School": this.school, "District": this.district, "State": this.selectedState
                    };
                    axios.post('account/createpurchaser', postData)
                        .then((res) => {
                            if (res.data.length > 0) {
                                this.purchaserMessage = "Purchaser " + res.data + " is already using this email address."
                                this.purchaserFail = true;
                            } else {
                                this.purchaserMessage = "Purchaser created successfully.";
                                this.purchaserFail = false;
                                this.searchUser = this.createEmail;
                                this.UserSearch();
                                this.ClearCreatePurchaser();
                            }
                        })
                        .catch((error) => {
                            this.purchaserMessage = error.response.data;
                            this.purchaserFail = true;
                        });
                }
            },

            EmailSendVerify(username, fullName) {
                this.ClearWarnings();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showEmailSend = true;
            },

            ForceVerify(username, fullName) {
                this.ClearWarnings();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showForce = true;
            },

            PwdVerify(username, fullName) {
                this.ClearWarnings();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showReset = true;
            },

            ResetPwd() {
                var username = this.activeUsername;
                var fullName = this.activeFullName;
                this.ClearWarnings();
                var postData = { "Username": username };
                axios.post('account/resetpassword', postData)
                    .then(() => {
                        this.manageMessage = fullName + "'s password has been reset.";
                    })
                    .catch((error) => {
                        this.manageMessage = error.response.data;
                        this.manageFail = true;
                    });
            },

            ResendInvite() {
                var username = this.activeUsername;
                var fullName = this.activeFullName;
                this.ClearWarnings();
                var postData = { "Username": username };
                axios.post('account/resendinvite', postData)
                    .then(() => {
                        this.manageMessage = "A reminder has been emailed to " + fullName + ".";
                    })
                    .catch((error) => {
                        this.manageMessage = error.response.data;
                        this.manageFail = true;
                    });
            },

            ForceReport() {
                var username = this.activeUsername;
                var fullName = this.activeFullName;
                this.ClearWarnings();
                var postData = { "Username": username };
                axios.post('admin/forcereport', postData)
                    .then(() => {
                        this.manageMessage = fullName + "'s report has been forced.";
                    })
                    .catch((error) => {
                        this.manageMessage = error.response.data;
                        this.manageFail = true;
                    });
            },

            DeleteVerify(username, fullName) {
                this.ClearWarnings();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.showDelete = true;
            },

            DeleteUser() {
                var username = this.activeUsername;
                var fullName = this.activeFullName;
                this.ClearWarnings();
                var postData = { "Username": username };
                axios.post('account/deleteuser', postData)
                    .then((res) => {
                        if (res.data.length > 0) { // Message returned means account has data so deletion was blocked
                            this.manageMessage = "Account can't be deleted. " + fullName + " has already " + res.data + ".";
                            this.manageFail = true;
                        } else {
                            this.manageMessage = fullName + "'s account has been removed.";
                            for (var i = 0; i < this.userData.length; i++) {
                                if (this.userData[i].username === username) {
                                    this.userData.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.searchMessage = error.response.data;
                        this.searchUserFail = true;
                    });
            },

            ManageSeats(username, fullName, seatsRemaining, seatsPurchased) {
                this.ClearWarnings();
                this.activeUsername = username;
                this.activeFullName = fullName;
                this.seatsRemaining = seatsRemaining;
                this.seatsPurchased = seatsPurchased;
                this.newSeatCount = seatsPurchased;
                this.showSeats = true;
            },

            UpdateSeats() {
                var postData = { "Username": this.activeUsername, "SeatCount": this.newSeatCount * 1 };
                axios.post('purchaser/updateseats', postData)
                    .then(() => {
                        this.searchUser = this.activeUsername;
                        this.UserSearch();
                        this.manageMessage = this.searchUser + "'s seat count has been updated.";
                        this.manageFail = false;
                    })
                    .catch((error) => {
                        this.manageMessage = error.response.data;
                        this.manageFail = true;
                    });
            },

            VerifySession() {
                this.showSeats = false;
                this.showNewSession = true;
            },

            CreateSession() {
                if (this.newSessionSeats > 0) {
                    var postData = { "Username": this.activeUsername, "SeatCount": this.newSessionSeats * 1 };
                    axios.post('purchaser/createsession', postData)
                        .then((res) => {
                            this.manageMessage = "New session " + res.data + " has been created.";
                            this.manageFail = false;
                            this.showNewSession = false;
                            this.$store.state.viewSession = res.data;
                        })
                        .catch((error) => {
                            this.manageMessage = error.response.data;
                            this.manageFail = true;
                        });
                } else {
                    this.manageMessage = "You must assign one or more seats to a new session.";
                    this.manageFail = true;
                }
            },

            BecomeUser(username, userRole) {
                axios.get('account/getfullname', { params: { searchUser: username }})
                    .then((res) => {
                        if (userRole === "Purchaser") {
                            this.$store.state.purchaserFullName = res.data;
                            this.$store.state.purchaserAlias = username;
                            this.$router.push('/purchaser');
                        } else {
                            this.$store.state.leaderFullName = res.data;
                            this.$store.state.leaderAlias = username;
                            this.$router.push('/leader');
                        }
                    });
            },

            Reinvite() {
                this.$router.push('/reinvite');
            },

            ClearWarnings() { // this.searchUser should NOT be initialized in this function
                this.searchMessage = "",
                this.purchaserMessage = "";
                this.searchUserFail = false;
                this.purchaserFail = false;
                this.activeUsername = "";
                this.activeFullName = "";
                this.showReset = false;
                this.showDelete = false;
                this.showSeats = false;
                this.showEmailSend = false;
                this.showForce = false;
                this.seatsPurchased = 0;
                this.seatsRemaining = 0;
                this.newSeatCount = 0;
                this.manageMessage = "";
                this.manageFail = false;
                this.showNewSession = false;
                this.newSessionSeats = 0;
            },

            ClearCreatePurchaser() {
                this.createEmail = "";
                this.seats = "";
                this.firstname = "";
                this.lastname = "";
                this.school = "";
                this.district = "";
                this.selectedState = "NS";
            },

            ValidateInput() {
                if (this.createEmail == "") {
                    this.purchaserMessage = "You must input a valid email address.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.seats == "") {
                    this.purchaserMessage = "You must choose a number of seats.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.firstname == "") {
                    this.purchaserMessage = "You must input a first name.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.lastname == "") {
                    this.purchaserMessage = "You must input a last name.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.school == "") {
                    this.purchaserMessage = "You must input a school.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.district == "") {
                    this.purchaserMessage = "You must input a district.";
                    this.purchaserFail = true;
                    return false;
                }

                if (this.selectedState === "NS" || this.selectedState == "") {
                    this.purchaserMessage = "You must select a state.";
                    this.purchaserFail = true;
                    return false;
                }

                this.purchaserMessage = "";
                this.purchaserFail = false;
                return true;
            }
        },

        mounted() {
            document.title = "Turnaround 360";

            this.windowWidth = window.innerWidth;

            window.onresize = () => {
                this.windowWidth = window.innerWidth;
            }

            if (this.$store.state.loggedName == "") {
                this.$router.push('/reset');
            } else {
                if (this.$store.state.searchUser != "") {  // Persist search data if user is clicking back button from dashboard accessed from this page.
                    this.searchUser = this.$store.state.searchUser;
                    this.UserSearch();
                }
            }
        }
    }
</script>

<style scoped>
    button {
        margin-bottom: 8px;
    }

    .ctr {
        text-align: center;
    }

    .lbl {
        margin-bottom: 8px;
    }

    button {
        background-color: #0d6efd;
        border-color: transparent;
        color: #ffffff;
        margin-right: 8px;
    }

    button:hover {
        background-color: #68a4fd;
        border-color: #ab935c;
        border-width: 1px;
    }

    .warning {
        color: #e60000;
    }

    .notice {
        color: #0d6efd;
    }

    .normalstyle {
        border-color: #808080;
    }

    .warnstyle {
        border-color: #e60000;
    }

    label {
        text-align: left;
    }

    input {
        margin-bottom: 8px;
    }

    .vsize {
        width: 100%;
        height: 42px;
    }

    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
        margin-top: 6px;
    }

    .leftbtn {
        width: 130px;
    }

    .verifybtn {
        width: 75px;
        margin-left: 10px;
    }

    .seatbtn {
        width: 150px;
        margin-left: 10px;
    }

    .createbtn {
        width: 175px;
    }

    .btn-yes {
        background-color: #0099ff;
    }

    .btn-no {
        background-color: #850021;
    }

    .verifybox {
        border: 5px solid #000099;
        padding: 10px;
        background-color: #e6f7ff;
    }

    .vspace {
        margin-top: 22px;
    }

    .userselect {
        color: #0039e6;
        cursor: pointer;
    }

    .seatinput {
        margin-left: 15px;
        width: 50px;
    }

    .subhdr {
        background-color: #d1d1e0;
        padding: 8px;
    }

    .userlist {
        background-color: #cce0ea;
        margin: 25px 0;
    }

    .newacct {
        background-color: #e1e9ed;
        padding: 8px;
        margin-top: 20px;
    }

    .smalltable {
        width: 1400px;
    }

    tr {
        border-bottom: solid 1px grey;
    }

    tr:last-child {
            border-bottom: none;
        }

    #wrapper {
        min-height: calc(90vh - 50px);
    }
</style>