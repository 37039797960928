
<template>
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
    <br />
</template>

<script>
    export default {
        name: "BarChart",

        props: {
            selfscore: Number,
            superscore: Number,
            teacherscore: Number,
            leadershipscore: Number,
            compositescore: Number,
            title: String
        },

        data() {
            return {
                series: [{
                    data: [this.selfscore, this.superscore, this.teacherscore, this.leadershipscore, this.compositescore]
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 175,
                        width: 300,
                        toolbar: {
                            show: false,
                            offsetX: 0,
                            offsetY: 0
                        }
                    },
                    legend: {
                        show: false,
                        showForSingleSeries: false,
                        showForNullSeries: false,
                        showForZeroSeries: false,
                    },
                    plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#660066'],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'start',
                        style: {
                            colors: ['#fff']
                        },
                        formatter: function (val, opt) {
                            if (val > 0) { return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val }
                        },
                        offsetX: 0,
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    xaxis: {
                        min: 0,
                        max: 8,
                        tickAmount: 8,
                        categories: ["Self", "Supervisor", "Teachers", "Leadership Team", "Composite Score"]
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    title: {
                        text: this.title,
                        align: 'left',
                        floating: true,
                        style: {
                            fontSize: '10px'
                        }
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function () {
                                    return ''
                                }
                            }
                        }
                    }
                }
            }
        },

        beforeUpdate() {
            this.series = [{
                data: [this.selfscore, this.superscore, this.teacherscore, this.leadershipscore, this.compositescore]
            }];

            this.chartOptions = {
                ...this.chartOptions, ...{
                    title: {
                        text: this.title
                    },
                }
            }
        }
    }
</script>